import React from "react";
import HeroSlider, { MenuNav, Overlay, Slide } from "hero-slider";
import * as $ from "jquery";
import "hero-slider/dist/index.css";
import Wrapper from "./Wrapper";
import Title from "../../Typography/Title";
import Subtitle from "../../Typography/Subtitle";
import banner2 from "assets/images/banner/banner2.jpg";
import banner3 from "assets/images/banner/banner3.jpg";
import banner4 from "assets/images/banner/banner4.jpg";
import banner5 from "assets/images/banner/banner5.jpg";
import { useState } from "react";
import "./HomeSlider.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const countyClare = "https://i.imgur.com/idjXzVQ.jpg";
const craterRock = "https://i.imgur.com/8DYumaY.jpg";
const giauPass = "https://i.imgur.com/8IuucQZ.jpg";

const HomeSlider = () => {
  let navigate = useNavigate();
  const [slide, setSlide] = useState(1);
  useEffect(() => {
    $(".slider_text_fade_in").fadeOut(500);

    setTimeout(() => {
      $(".slider_text_fade_in" + slide).fadeIn(500);
    }, 500);
  }, [slide]);
  return (
    <HeroSlider
      height={"100vh"}
      autoplay
      controller={{
        initialSlide: 1,
        slidingDuration: 500,
        slidingDelay: 100,
        onSliding: (nextSlide) => {
          setSlide(nextSlide);
        },
        onBeforeSliding: (previousSlide, nextSlide) => {},
        onAfterSliding: (nextSlide) => {},
      }}
      style={{
        background: "black",
        minHeight: "600px",
      }}
    >
      <Overlay>
        <Wrapper>
          {slide === 1 ? (
            <>
              <div className="slider_text_fade_in slider_text_fade_in1">
                <Title>Formation sécurité incendie</Title>
                <Subtitle>Formation de sécurité incendie SSIAP 1-2-3</Subtitle>
                <div className="w-100 mt-5">
                  <button
                    className="btn btn-primary mx-3 mb-3"
                    style={{ display: "inline-block", fontWeight: "600" }}
                    onClick={() => {
                      navigate("/nos-formations");
                    }}
                  >
                    Consulter Les Formations
                  </button>
                  <button
                    className="btn btn-outline-light mx-3 mb-3"
                    style={{ display: "inline-block" }}
                    onClick={() => {
                      navigate("/reserver-des-formations");
                    }}
                  >
                    Reserver votre formation
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {slide === 2 ? (
            <>
              <div className="slider_text_fade_in slider_text_fade_in2">
                <Title>Formation secourisme</Title>
                <Subtitle>
                  Préparez-vous à un certificat de secourisme professionnel
                </Subtitle>
                <div className="w-100 mt-5">
                  <button
                    className="btn btn-primary mx-3 mb-3"
                    style={{ display: "inline-block", fontWeight: "600" }}
                    onClick={() => {
                      navigate("/nos-formations");
                    }}
                  >
                    Consulter Les Formations
                  </button>
                  <button
                    className="btn btn-outline-light mx-3 mb-3"
                    style={{ display: "inline-block" }}
                    onClick={() => {
                      navigate("/reserver-des-formations");
                    }}
                  >
                    Reserver votre formation
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {slide === 3 ? (
            <>
              <div className="slider_text_fade_in slider_text_fade_in3">
                <Title>Formation Agent de Prévention et de Sécurité</Title>
                <Subtitle>
                  Préparez votre certificat professionnel d'agent de sécurité
                  CQP - APS
                </Subtitle>
                <div className="w-100 mt-5">
                  <button
                    className="btn btn-primary mx-3 mb-3"
                    style={{ display: "inline-block", fontWeight: "600" }}
                    onClick={() => {
                      navigate("/nos-formations");
                    }}
                  >
                    Consulter Les Formations
                  </button>
                  <button
                    className="btn btn-outline-light mx-3 mb-3"
                    style={{ display: "inline-block" }}
                    onClick={() => {
                      navigate("/reserver-des-formations");
                    }}
                  >
                    Reserver votre formation
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {slide === 4 ? (
            <>
              <div className="slider_text_fade_in slider_text_fade_in4">
                <Title>Formation H0 B0 – Habilitation Électrique</Title>
                <Subtitle>
                  Travailler en toute sécurité à proximité d'installations
                  électriques
                </Subtitle>
                <div className="w-100 mt-5">
                  <button
                    className="btn btn-primary mx-3 mb-3"
                    style={{ display: "inline-block", fontWeight: "600" }}
                    onClick={() => {
                      navigate("/nos-formations");
                    }}
                  >
                    Consulter Les Formations
                  </button>
                  <button
                    className="btn btn-outline-light mx-3 mb-3 font-bold"
                    style={{ display: "inline-block" }}
                    onClick={() => {
                      navigate("/reserver-des-formations");
                    }}
                  >
                    Reserver votre formation
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </Wrapper>
      </Overlay>

      <Slide
        shouldRenderMask
        label="Formation sécurité incendie"
        background={{
          backgroundImageSrc: banner2,
        }}
      />

      <Slide
        shouldRenderMask
        label="Formation secourisme"
        background={{
          backgroundImageSrc: banner3,
        }}
      />

      <Slide
        shouldRenderMask
        label="Formation Agent de Prévention et de Sécurité"
        background={{
          backgroundImageSrc: banner4,
        }}
      />

      <Slide
        shouldRenderMask
        label="Formation H0 B0 – Habilitation Électrique"
        background={{
          backgroundImageSrc: banner5,
        }}
      />

      <MenuNav />
    </HeroSlider>
  );
};

export default HomeSlider;
