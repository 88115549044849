import { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Pour récupérer l'URL courante

const GoogleAnalytics = () => {
  const location = useLocation(); // Récupère l'URL actuelle

  useEffect(() => {
    // Ajouter les scripts Google Analytics
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=UA-199013152-1";

    const script2 = document.createElement("script");
    script2.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-199013152-1', { debug_mode: true });
    `;

    document.head.appendChild(script1);
    document.head.appendChild(script2);

    // Envoi d'un événement personnalisé ou d'une page vue
    if (location.pathname === "/404") {
      window.gtag?.("event", "page_view", {
        page_path: location.pathname,
        page_title: "404 Not Found",
        status_code: 404, // Ajout du code de statut
      });
    } else {
      window.gtag?.("event", "page_view", {
        page_path: location.pathname,
        page_title: document.title,
      });
    }

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, [location.pathname]); // Re-exécuter à chaque changement de chemin

  return null;
};

export default GoogleAnalytics;
