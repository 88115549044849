import Subtitle from "components/Typography/Subtitle";
import Title from "components/Typography/Title";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

export default () => {
  let navigate = useNavigate();

  return (
    <>
      <section
        className="relative block py-24 lg:py-3"
        style={{ background: "#28AE60" }}
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center">
            <div className="w-full lg:w-10/12 px-4 mt-3">
              <div className="w-100">
                <Title>Devenir Formateur Partenaire</Title>
                <Subtitle>
                  Nous recherchons des professionnels pour accompagner notre{" "}
                  <br></br> développement et répondre aux besoins de nos clients
                  partout en France.
                </Subtitle>
                <div className="w-100 mt-5">
                  <button
                    className="btn btn-outline-light font-bold mx-3 w-100 text-white text-hover-success"
                    style={{
                      display: "inline-block",
                      maxWidth: "250px",
                      minHeight: "60px",
                    }}
                    onClick={() => {
                      navigate("/partenariat-formateur");
                    }}
                  >
                    Devenez partenaire
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
