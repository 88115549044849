import HomeFooter from "components/Footers/homeFooter/HomeFooter";
import AppNavbar from "components/Headers/navbar/NavbarSecond";
import HomeOurPartners from "components/Home/homeOurPartners/HomeOurPartners";
import { useMetadata } from "components/SEO/Metadata";
import SEO from "components/SEO/SEO";
import React, { useState } from "react";
import { useEffect } from "react";

import { Outlet, useNavigate } from "react-router-dom";

export default () => {
  let navigate = useNavigate();

  const { metadata, loading } = useMetadata();

  useEffect(() => {
    if (metadata) {
      // Utiliser les métadonnées si elles existent
      console.log("Métadonnées chargées:", metadata);
    }
  }, [metadata]);

  const navItems = [
    { title: "Accueil", link: "/", dropdown: false },
    { title: "Qui sommes-nous?", link: "/qui-sommes-nous", dropdown: false },
    {
      title: "Nos Formations",
      link: "/nos-formations",
      dropdown: true,
      row: true,
      ulClassName: "dropdown-menu-formations align-end title",
      items: [
        {
          xs: 12,
          md: 4,
          items: [
            {
              title: "SÉCURITÉ INCENDIE",
              link: "/nos-formations/securite-incendie",
              items: [
                {
                  title: "SSIAP 1",
                  link: "/nos-formations/securite-incendie/ssiap1",
                },
                {
                  title: "SSIAP 2",
                  link: "/nos-formations/securite-incendie/ssiap2",
                },
                {
                  title: "SSIAP 3",
                  link: "/nos-formations/securite-incendie/ssiap3",
                },
                {
                  title: "RECYCLAGE SSIAP 1",
                  link: "/nos-formations/securite-incendie/recyclage-ssiap1",
                },
                {
                  title: "RECYCLAGE SSIAP 2",
                  link: "/nos-formations/securite-incendie/recyclage-ssiap2",
                },
                {
                  title: "RECYCLAGE SSIAP 3",
                  link: "/nos-formations/securite-incendie/recyclage-ssiap3",
                },
                {
                  title: "REMISE A NIVEAU SSIAP 1",
                  link: "/nos-formations/securite-incendie/remise-a-niveau-ssiap1",
                },
                {
                  title: "REMISE A NIVEAU SSIAP 2",
                  link: "/nos-formations/securite-incendie/remise-a-niveau-ssiap2",
                },
                {
                  title: "REMISE A NIVEAU SSIAP 3",
                  link: "/nos-formations/securite-incendie/remise-a-niveau-ssiap3",
                },
                {
                  title: "EPI EQUIPIER PREMIERE INTERVENTION",
                  link: "/nos-formations/securite-incendie/epi-equipier-premiere-intervention",
                },
              ],
            },
          ],
        },
        {
          xs: 12,
          md: 5,
          items: [
            {
              title: "SECOURISME",
              link: "/nos-formations/secourisme/",
              items: [
                {
                  title: "SST(Sauveteur Secouriste du Travail)",
                  link: "/nos-formations/secourisme/sst-sauveteur-secouriste-du-travail",
                },
                {
                  title: "Recyclage SST",
                  link: "/nos-formations/secourisme/recyclage-sst",
                },
              ],
            },
            {
              title: "HABILITATIONS ELECTRIQUES",
              link: "/nos-formations/habilitations-electriques",
              items: [
                {
                  title: "H0-B0",
                  link: "/nos-formations/habilitations-electriques/ho-bo",
                },
              ],
            },
          ],
        },
        {
          xs: 12,
          md: 3,
          items: [
            {
              title: "SÉCURITÉ SÛRETÉ",
              link: "/nos-formations/securite-surete/",
              items: [
                {
                  title: "TFP APS",
                  link: "/nos-formations/securite-surete/cqp-aps",
                },
                {
                  title: "MAC APS",
                  link: "/nos-formations/securite-surete/mac-aps",
                },
              ],
            },
          ],
        },
        {
          xs: 12,
          md: 10,
          items: [
            {
              title: "",
              link: "",
              items: [],
            },
          ],
        },
        {
          xs: 12,
          md: 2,
          items: [
            {
              title: "Voir tout",
              link: "/nos-formations",
              items: [],
            },
          ],
        },
      ],
    },
    // { title: "Documents", link: "/documents", dropdown: false },
    {
      title: "Contact",
      link: "/contactus",
      dropdown: true,
      row: false,
      ulClassName: "dropdown-menu-contact",
      items: [
        { title: "Nous Ecrire", link: "/contact" },
        { title: "Faire une réservation", link: "/reserver-des-formations" },
        { title: "Demander un devis", link: "/demander-un-devis" },
        { title: "Devenir Parnetaire", link: "/partenariat-formateur" },
      ],
    },
  ];

  return (
    <>
      {metadata && metadata.title && !loading ? (
        <SEO metadata={metadata} />
      ) : (
        <></>
      )}
      <AppNavbar navItems={navItems} />
      <Outlet />
      <HomeOurPartners></HomeOurPartners>
      <HomeFooter></HomeFooter>
    </>
  );
};
