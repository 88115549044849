import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
// import Map from "react-google-maps"; // Ensure you have react-google-maps installed
import { makeStyles } from "@mui/styles";
import { Container, Grid, Typography } from "@mui/material";
import QuoteForm from "./Form/QuoteForm";
import PartnerForm from "./Form/PartnerForm";
import ContactForm from "./Form/ContactForm";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmailIcon from "@mui/icons-material/Email";
import RegistrationForm from "./Form/RegistrationForm";

const useStyles = makeStyles({
  formControl: {
    margin: "10px 0",
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: 20,
  },
});

export default ({ motif }) => {
  let navigate = useNavigate();

  const classes = useStyles();

  const valuesInfo = {
    adresse: "42 Rue Le Peletier, Paris",
    tel: "+33143494022",
    horaire: "Lun - Ven, 8AM - 5PM",
    email: "contact@isis-formation.net",
  };

  return (
    <AnimationRevealPage>
      <section className="contact-section mt-5">
        <Container>
          <Grid container className=" mb-5 pb-4">
            <iframe
              width="1110"
              height="484"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=1110&amp;height=484&amp;hl=en&amp;q=42%20rue%20le%20peletier%20Paris+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </Grid>
          <div id="success_send"></div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {motif === "demander-un-devis" ? (
                <h3 className="text-3xl font-semibold mb-3 text-center">
                  DEMANDEZ VOTRE DEVIS EN LIGNE
                </h3>
              ) : motif === "reserver-des-formations" ? (
                <h3 className="text-3xl font-semibold mb-3 text-center">
                  RÉSERVEZ EN LIGNE
                </h3>
              ) : (
                <h3 className="text-3xl font-semibold mb-3 text-center">
                  NOUS RÉPONDONS A TOUTES VOS QUESTIONS
                </h3>
              )}
            </Grid>

            <Grid item lg={8}>
              <h3
                className="text-base font-semibold mb-3 text-left"
                style={{ color: "#ff7300" }}
              >
                *Vous devez d'abord saisir le numéro de téléphone.
              </h3>
              {motif === "demander-un-devis" ? (
                <QuoteForm />
              ) : motif === "partenariat-formateur" ? (
                <PartnerForm />
              ) : motif === "reserver-des-formations" ? (
                <RegistrationForm callback={() => {}} />
              ) : (
                <ContactForm />
              )}
            </Grid>
            <Grid item lg={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="media contact-info">
                    <LocationOnIcon
                      className={classes.contactInfoIcon}
                      style={{ marginBottom: "10px" }}
                    />
                    <div className="media-body">
                      <h3>Adresse</h3>
                      <p>{valuesInfo.adresse}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="media contact-info">
                    <PhoneIcon
                      className={classes.contactInfoIcon}
                      style={{ marginBottom: "10px" }}
                    />
                    <div className="media-body">
                      <h3>Téléphone</h3>
                      <p>{valuesInfo.tel}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="media contact-info">
                    <AccessTimeIcon
                      className={classes.contactInfoIcon}
                      style={{ marginBottom: "10px" }}
                    />
                    <div className="media-body">
                      <h3>Heures d'ouverture</h3>
                      <p>{valuesInfo.horaire}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="media contact-info">
                    <EmailIcon
                      className={classes.contactInfoIcon}
                      style={{ marginBottom: "10px" }}
                    />
                    <div className="media-body">
                      <h3>Email</h3>
                      <p>{valuesInfo.email}</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
    </AnimationRevealPage>
  );
};
