import { Icon } from "@mui/material";
import MentionsLegales from "components/MentionsLegales/MentionsLegales";
import AboutUsScreen from "screens/AboutUsScreen/AboutUsScreen";
import ContactUsScreen from "screens/ContactUsScreen/ContactUsScreen";
import DocumentsScreen from "screens/DocumentsScreen/DocumentsScreen";
import FormationCategoryScreen from "screens/FormationCategoryScreen/FormationCategoryScreen";
import FormationScreen from "screens/FormationScreen/FormationScreen";
import HomeScreen from "screens/HomeScreen/HomeScreen";
import NosFormationsScreen from "screens/NosFormationsScreen/NosFormationsScreen";

const HomeRoutes = [
  {
    type: "collapse",
    name: "Accueil",
    key: "home",
    icon: <Icon fontSize="small">Home</Icon>,
    route: "/",
    component: <HomeScreen />,
    role: "home",
  },
  // {
  //   type: "collapse",
  //   name: "Documents",
  //   key: "documents",
  //   icon: <Icon fontSize="small">file</Icon>,
  //   route: "/documents",
  //   component: <DocumentsScreen />,
  //   role: "home",
  // },
  {
    type: "collapse",
    name: "Qui sommes nous",
    key: "qui-sommes-nous",
    icon: <Icon fontSize="small">about</Icon>,
    route: "/qui-sommes-nous",
    component: <AboutUsScreen />,
    role: "home",
  },
  {
    type: "collapse",
    name: "Nos Formations",
    key: "nos-formations",
    icon: <Icon fontSize="small">about</Icon>,
    route: "/nos-formations",
    component: <NosFormationsScreen />,
    role: "home",
  },
  {
    type: "collapse",
    name: "Formation catégorie",
    key: "nos-formations",
    icon: <Icon fontSize="small">about</Icon>,
    route: "/nos-formations/:id",
    component: <FormationCategoryScreen />,
    role: "home",
  },
  {
    type: "collapse",
    name: "Formation",
    key: "nos-formations",
    icon: <Icon fontSize="small">about</Icon>,
    route: "/nos-formations/:id_cat/:id",
    component: <FormationScreen />,
    role: "home",
  },
  {
    type: "collapse",
    name: "Contact",
    key: "contact",
    icon: <Icon fontSize="small">about</Icon>,
    route: "/contact/",
    component: <ContactUsScreen motif="" />,
    role: "home",
  },
  {
    type: "collapse",
    name: "Contact",
    key: "contact",
    icon: <Icon fontSize="small">about</Icon>,
    route: "/reserver-des-formations",
    component: <ContactUsScreen motif="reserver-des-formations" />,
    role: "home",
  },
  {
    type: "collapse",
    name: "Contact",
    key: "contact",
    icon: <Icon fontSize="small">about</Icon>,
    route: "/demander-un-devis",
    component: <ContactUsScreen motif="demander-un-devis" />,
    role: "home",
  },
  {
    type: "collapse",
    name: "Contact",
    key: "contact",
    icon: <Icon fontSize="small">about</Icon>,
    route: "/partenariat-formateur",
    component: <ContactUsScreen motif="partenariat-formateur" />,
    role: "home",
  },
  {
    type: "collapse",
    name: "MentionsLegales",
    key: "MentionsLegales",
    icon: <Icon fontSize="small">about</Icon>,
    route: "/mentions-legales",
    component: <MentionsLegales />,
    role: "home",
  },
  // {
  //   type: "collapse",
  //   name: "Contact",
  //   key: "contact",
  //   icon: <Icon fontSize="small">about</Icon>,
  //   route: "/contact/:motif",
  //   component: <ContactUsScreen />,
  //   role: "home",
  // },
];

export default HomeRoutes;
