import React from "react";
import {
  Grid,
  Typography,
  Box,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BASE_URL_Image } from "services/server";

const ImageFormation = styled("div")({
  width: "150px",
  height: "auto",
  margin: "auto",
});

const LinkF = styled(Link)({ color: "#28ae60", textDecoration: "none" });

export default ({ formation }) => {
  return (
    <Box sx={{ padding: 3, maxWidth: "1200px" }} className="formation_details">
      <Grid container spacing={2} alignContent={"center"}>
        {formation.image ? (
          <>
            <Grid item xs={12}>
              <div className="col-lg-2 pl-lg-4 container text-center">
                <ImageFormation className="accordion-content card mb-2 p-3 divBox">
                  <img
                    className="card-img-top"
                    title={formation.title}
                    src={BASE_URL_Image + formation.image}
                    alt={formation.title}
                    height="200px"
                  />
                </ImageFormation>
              </div>
            </Grid>
          </>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            color="secondary"
            gutterBottom
            textAlign={"left"}
            style={{ fontWeight: "bold" }}
          >
            <b>{formation.version}</b>
          </Typography>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            style={{
              fontWeight: "bold",
              marginBottom: "40px",
              marginTop: "20px",
            }}
          >
            {formation.title}
          </Typography>
          {formation.description.map((item, index) => {
            if (item.type === "text") {
              return (
                <Typography
                  key={index}
                  variant="body1"
                  gutterBottom
                  textAlign={"left"}
                  style={{ marginBottom: "20px" }}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              );
            } else if (item.type === "link") {
              return (
                <Typography key={index} variant="body1" textAlign={"left"}>
                  <LinkF href={item.url}>{item.content}</LinkF>{" "}
                  {formation.description[index + 1] &&
                    formation.description[index + 1].type === "text" && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formation.description[index + 1].content,
                        }}
                      />
                    )}
                </Typography>
              );
            } else if (item.type === "list") {
              return (
                <List
                  key={index}
                  sx={{
                    listStyleType: "square",
                    pl: "50px",
                    "& .MuiListItem-root": {
                      display: "list-item",
                      listStyleType: "square",
                    },
                  }}
                >
                  {item.items.map((listItem, idx) => (
                    <ListItem key={idx}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        textAlign={"left"}
                        dangerouslySetInnerHTML={{ __html: listItem }}
                      />
                    </ListItem>
                  ))}
                </List>
              );
            }
            return null;
          })}
          {formation.quote ? (
            <>
              <figure
                className="bg-white p-3 rounded my-3"
                style={{
                  borderLeft: ".25rem solid #f68e9d",
                  textAlign: "left",
                }}
              >
                <blockquote style={{ marginLeft: 0 }}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    textAlign={"left"}
                    dangerouslySetInnerHTML={{ __html: formation.quote.text }}
                  />
                  <br />
                </blockquote>
                <figcaption className="blockquote-footer mb-0 font-italic">
                  Pour plus d'information:
                  <List
                    sx={{
                      listStyleType: "square",
                      pl: "50px",
                      "& .MuiListItem-root": {
                        display: "list-item",
                        listStyleType: "square",
                      },
                    }}
                  >
                    {formation.quote.links.map((link, index) => (
                      <ListItem key={index}>
                        <LinkF href={link.url} target="_blank">
                          {link.text}
                        </LinkF>
                      </ListItem>
                    ))}
                  </List>
                </figcaption>
              </figure>
            </>
          ) : (
            <></>
          )}
          <Typography variant="body1" gutterBottom></Typography>
          <Box>
            {formation.details.map((detail, index) => (
              <Accordion className="my-2 shadow-none" key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    {detail.header}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ textAlign: "left" }}>
                  {typeof detail.content === "string" ? (
                    <Typography
                      variant="body1"
                      gutterBottom
                      textAlign={"left"}
                      dangerouslySetInnerHTML={{ __html: detail.content }}
                    />
                  ) : (
                    <>
                      <img
                        src={BASE_URL_Image + detail.content.image}
                        alt={detail.header}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          marginBottom: "20px",
                        }}
                      />
                      {detail.content.elements ? (
                        <>
                          {detail.content.elements.map((element, elIndex) => {
                            if (element.type === "text") {
                              return (
                                <Typography
                                  key={elIndex}
                                  dangerouslySetInnerHTML={{
                                    __html: element.content,
                                  }}
                                />
                              );
                            } else if (element.type === "link") {
                              return (
                                <LinkF key={elIndex} href={element.url}>
                                  {element.content}
                                </LinkF>
                              );
                            }
                            return null;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                      <Typography
                        variant="body1"
                        gutterBottom
                        textAlign={"left"}
                        dangerouslySetInnerHTML={{
                          __html: detail.content.text,
                        }}
                      />
                    </>
                  )}
                  {detail.items && (
                    <List
                      sx={{
                        listStyleType: "square",
                        pl: "50px",
                        color: "GrayText",
                        "& .MuiListItem-root": {
                          display: "list-item",
                          listStyleType: "square",
                        },
                      }}
                    >
                      {detail.items.map((item, index) => (
                        <>
                          {item.items ? (
                            <>
                              <Typography
                                variant="body1"
                                gutterBottom
                                textAlign={"left"}
                                sx={{
                                  ml: "-50px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              />
                              {item.items &&
                                item.items.map((it_item, index) => (
                                  <ListItem key={index}>
                                    <Typography
                                      dangerouslySetInnerHTML={{
                                        __html: it_item,
                                      }}
                                    />
                                  </ListItem>
                                ))}
                            </>
                          ) : (
                            <>
                              <ListItem key={index}>
                                <Typography
                                  dangerouslySetInnerHTML={{ __html: item }}
                                />
                              </ListItem>
                            </>
                          )}
                        </>
                      ))}
                    </List>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
// const FormationsList = () => {
//   return (
//     <div>
//       {formations.map((formation, index) => (
//         <Formation key={index} formation={formation} />
//       ))}
//     </div>
//   );
// };

// export default FormationsList;

// export const abdou () = ({ formation }) => {
//   return (
//     <Box sx={{ padding: 3, maxWidth: "1200px" }}>
//       <Grid container spacing={2} alignContent={"center"}>
//         <Grid item xs={12}>
//           <div className="col-lg-2 pl-lg-4 container text-center">
//             <div className="accordion-content card mb-2 p-3 divBox">
//               <img
//                 className="card-img-top"
//                 src={formation.image}
//                 alt={formation.title}
//               />
//             </div>
//           </div>
//           <Typography variant="h4" component="h2" gutterBottom>
//             {formation.title}
//           </Typography>
//           <Typography variant="subtitle1" gutterBottom>
//             {formation.version}
//           </Typography>
//           {formation.description.map((desc, index) => {
//             if (desc.type === "text") {
//               return (
//                 <Typography key={index} variant="body1">
//                   <span dangerouslySetInnerHTML={{ __html: desc.content }} />
//                 </Typography>
//               );
//             } else if (desc.type === "link") {
//               return (
//                 <Typography key={index} variant="body1">
//                   <LinkF href={desc.url}>{desc.content}</LinkF>{" "}
//                   {formation.description[index + 1] &&
//                     formation.description[index + 1].type === "text" && (
//                       <span
//                         dangerouslySetInnerHTML={{
//                           __html: formation.description[index + 1].content,
//                         }}
//                       />
//                     )}
//                 </Typography>
//               );
//             } else if (desc.type === "list") {
//               return (
//                 <List key={index}>
//                   {desc.items.map((item, itemIndex) => (
//                     <ListItem
//                       key={itemIndex}
//                       dangerouslySetInnerHTML={{ __html: item }}
//                     />
//                   ))}
//                 </List>
//               );
//             }
//             return null;
//           })}
//           <Typography variant="body1" gutterBottom>
//             {formation.quote.text}
//           </Typography>
//           {formation.quote.links.map((link, index) => (
//             <LinkF key={index} href={link.url}>
//               {link.text}
//             </LinkF>
//           ))}
//         </Grid>
//         {formation.details.map((detail, index) => (
//           <Grid item xs={12} key={index}>
//             <Accordion>
//               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                 <Typography variant="h6">{detail.header}</Typography>
//               </AccordionSummary>
//               <AccordionDetails>
//                 {typeof detail.content === "string" ? (
//                   <Typography
//                     dangerouslySetInnerHTML={{ __html: detail.content }}
//                   />
//                 ) : (
//                   <Box>
//                     <img src={detail.content.image} alt="Accessibilité" />
//                     {detail.content.elements.map((element, elIndex) => {
//                       if (element.type === "text") {
//                         return (
//                           <Typography
//                             key={elIndex}
//                             dangerouslySetInnerHTML={{
//                               __html: element.content,
//                             }}
//                           />
//                         );
//                       } else if (element.type === "link") {
//                         return (
//                           <LinkF key={elIndex} href={element.url}>
//                             {element.content}
//                           </LinkF>
//                         );
//                       }
//                       return null;
//                     })}
//                   </Box>
//                 )}
//               </AccordionDetails>
//             </Accordion>
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//   );
// };
