import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import aboutImage from "assets/images/about/aboutus.png";
import "./AboutUsPresentation.css";
import { useSelector } from "react-redux";
export default () => {
  let navigate = useNavigate();
  return (
    <>
      <div id="aboutUs">
        <section
          className="pb-20 relative block "
          style={{ paddingTop: "80px" }}
        >
          <div className="container-fluid mx-auto px-4 pt-4">
            <div className="flex flex-wrap items-center">
              <div className="w-full md:w-6/12 p-0 mr-auto ml-auto m-0 d-flex align-items-center justify-content-center">
                <img
                  alt="..."
                  className="max-w-full rounded-lg "
                  style={{ width: "100%", height: "auto", maxWidth: "400px" }}
                  src={aboutImage}
                />
              </div>
              <div className="w-full md:w-5/12 px-4 my-3 mr-auto ml-auto">
                <h3 className="text-3xl font-semibold mb-3 text-left">
                  Présentation de ISIS Formation
                </h3>
                <p className="text-base font-light leading-relaxed mt-4 mb-4 text-blueGray-600  text-left">
                  Nous intervenons dans votre établissement dans toute la
                  France, ou dans nos locaux au{" "}
                  <b>42, rue Le peletier 75009 Paris</b>. <br />
                  Pour vous former au métier de la sécurité incendie, vous
                  sensibiliser sur les dangers de l’incendie et vous apprendre
                  les réflexes de secourisme, utiles lors des accidents du
                  travail.
                  <br />
                  <b>ISIS FORMATION</b>,fort d’expérience dans l’enseignement du
                  secourisme et de l’incendie, s’est spécialisé dans l’animation
                  et la réalisation de formations dans le milieu professionnel.
                  Formations adaptées et personnalisées à votre activité ou
                  future activité. L’équipe pédagogique est exclusivement
                  composée de professionnels confirmés et diplômés.
                </p>
                <div className="w-100 ">
                  <Button
                    variant="contained"
                    color="success"
                    className="w-100 mx-2 mt-2"
                    style={{
                      fontSize: "14px",
                      maxWidth: "250px",
                      minHeight: "50px",
                      display: "inline-block",
                    }}
                    onClick={() => {
                      navigate("/nos-formations");
                    }}
                  >
                    Consulter Les Formations
                  </Button>

                  <Button
                    startIcon={
                      <i
                        class="fa fa-play"
                        style={{ fontSize: "9px", marginRight: "9px" }}
                      ></i>
                    }
                    variant="outlined"
                    color="success"
                    className="w-100 mx-2 mt-2"
                    style={{
                      fontSize: "14px",
                      maxWidth: "300px",
                      minHeight: "50px",
                      display: "inline-block",
                    }}
                    onClick={() => {
                      navigate("/reserver-des-formations");
                    }}
                  >
                    Reserver votre formation
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
