import AboutUs from "components/Home/AboutUs/AboutUs";
import PageTitle from "components/Typography/PageTitle/PageTitle";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

export default () => {
  let navigate = useNavigate();

  return (
    <>
      <PageTitle title={"Qui Sommes Nous?"}>
        <div className="w-100 mt-5">
          <button
            className="btn btn-primary mx-3 mb-3 text-base"
            style={{ display: "inline-block", fontWeight: "500" }}
            onClick={() => {
              navigate("/nos-formations");
            }}
          >
            Consulter Les Formations
          </button>
          <button
            className="btn btn-outline-light mx-3 mb-3 text-base"
            style={{ display: "inline-block" }}
            onClick={() => {
              navigate("/reserver-des-formations");
            }}
          >
            <div className="w-100 d-flex align-items-center justify-content-start">
              <i
                class="fa fa-play"
                style={{ fontSize: "9px", marginRight: "9px" }}
              ></i>{" "}
              Reserver votre formation
            </div>
          </button>
        </div>
      </PageTitle>
      <AboutUs></AboutUs>
    </>
  );
};
