// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  isLoading: true,
};

// ==============================|| APP REDUCER ||============================== //

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default appReducer;
