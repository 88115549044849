import Contact from "components/Contact/Contact";
import NosFormations from "components/Home/NosFormations/NosFormations";
import PageTitle from "components/Typography/PageTitle/PageTitle";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default ({ motif }) => {
  let navigate = useNavigate();

  const getTitle = () => {
    switch (motif) {
      case "partenariat-formateur":
        return "Devenir partenaire";
      case "demander-un-devis":
        return "Demander votre Devis maintenant";
      case "reserver-des-formations":
        return "Réservez maintenant";
      default:
        return "Contact";
    }
  };

  return (
    <>
      <PageTitle title={getTitle()}>
        <div className="w-100 mt-5">
          <button
            className="btn btn-primary mx-3 mb-3 text-base"
            style={{ display: "inline-block", fontWeight: "500" }}
            onClick={() => {
              navigate("/nos-formations");
            }}
          >
            Consulter Les Formations
          </button>
          <button
            className="btn btn-outline-light mx-3 mb-3 text-base"
            style={{ display: "inline-block" }}
            onClick={() => {
              navigate("/reserver-des-formations");
            }}
          >
            <div className="w-100 d-flex align-items-center justify-content-start">
              <i
                class="fa fa-play"
                style={{ fontSize: "9px", marginRight: "9px" }}
              ></i>{" "}
              Reserver votre formation
            </div>
          </button>
        </div>
      </PageTitle>
      {motif ? (
        <Contact motif={motif}></Contact>
      ) : (
        <Contact motif={""}></Contact>
      )}
    </>
  );
};
