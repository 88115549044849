import axios from "axios";

export const BASE_URL = "https://1formationssiap.fr/ws/public/api/";
export const BASE_URL_Image = "https://1formationssiap.fr/ws/public/";
// export const BASE_URL = "http://localhost:8000/api/";
// export const BASE_URL_Image = "http://localhost:8000/";

export const authApi = axios.create({
  baseURL: BASE_URL,
});

authApi.defaults.headers.common["Content-Type"] = "application/json";
authApi.defaults.withCredentials = true;

authApi.defaults.headers.common["Accept"] = "application/json";
authApi.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
