import React from "react";
import { Box, Typography } from "@mui/material";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { useState } from "react";

export default ({ number, name, extra }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust this threshold as needed
  });
  const [start, setStart] = useState(false);

  React.useEffect(() => {
    if (inView) {
      setStart(true);
    }
  }, [inView]);

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        borderBottom: "5px solid #ff7300",
      }}
      className="shadow-sm"
    >
      <Typography
        variant="body2"
        style={{ marginBottom: "10px", color: "#28ae60", fontWeight: "bold" }}
      >
        {name}
      </Typography>
      <div>
        {start && (
          <CountUp start={0} end={number} duration={4} delay={0}>
            {({ countUpRef }) => (
              <Typography
                variant="h4"
                ref={countUpRef}
                style={{ marginBottom: "10px", display: "inline" }}
              ></Typography>
            )}
          </CountUp>
        )}

        <Typography
          variant="subtitle1"
          style={{ marginBottom: "10px", display: "inline" }}
        >
          {extra}
        </Typography>
      </div>
    </Box>
  );
};
