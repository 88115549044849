import React from "react";
import logo from "assets/images/logo/logo-off.png";
import { useState, useEffect } from "react";
import "./HomeFooter.css";
import HomeContactUsForm from "../homeContactUsForm/HomeContactUsForm";

const HomeFooter = () => {
  return (
    <footer className="footer pt-0">
      <div className="footer_top">
        <div className="container-fluid px-lg-5">
          <div className="row">
            <div className="col-xl-3 col-md-6 col-lg-4">
              <div className="footer_widget">
                <div className="footer_logo">
                  <a href="/">
                    <img
                      src={logo}
                      title="logo-off.png"
                      alt="image"
                      height="60px"
                      width="auto"
                    />
                  </a>
                </div>
                <p
                  className="m-0"
                  style={{ textAlign: "left", fontSize: "14px" }}
                >
                  <b style={{ color: "#ff7300" }}>ISIS Formation</b> est
                  spécialisé dans la formation dans les secteurs de la sécurité
                  privée, la sécurité incendie et le secourisme.
                </p>

                <ul>
                  <li className="d-flex align-items-center justify-content-start">
                    42 rue Le Peletier 75009 Paris
                  </li>

                  <li className="d-flex align-items-center justify-content-start">
                    <i className="fa fa-phone"></i>{" "}
                    <a
                      style={{ marginLeft: "5px", textDecoration: "none" }}
                      href="tel:+33143494022"
                    >
                      +33143494022
                    </a>
                  </li>

                  <li className="d-flex align-items-center justify-content-start">
                    <i className="fa fa-envelope"></i>{" "}
                    <a
                      style={{ marginLeft: "5px", textDecoration: "none" }}
                      href="mailto:contact@isis-formation.net"
                    >
                      contact@isis-formation.net
                    </a>
                  </li>
                </ul>

                <div className="socail_links">
                  <ul className="d-flex align-items-center justify-content-start">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=100068852185930"
                        target="_blank"
                      >
                        <i className="fab fa-facebook text-white"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/1Ssiap" target="_blank">
                        <i className="fab fa-twitter text-white"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/1formationssiap-1formationssiap-b65922211/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin text-white"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3  offset-xl-1 col-md-6 col-lg-4">
              <div className="footer_widget">
                <h3 className="footer_title">Horaires d'ouverture</h3>
                <ul style={{ width: "100%", maxWidth: "300px", padding: "0" }}>
                  <li>
                    <a
                      className="w-100 row ml-0 "
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        className="col-5 d-flex align-items-center justify-content-start"
                        style={{ textAlign: "left" }}
                      >
                        <i
                          className="fas fa-clock fa-2x "
                          style={{ marginRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                        Lundi
                      </span>
                      <span className="col-7" style={{ textAlign: "left" }}>
                        09:00 à 17:00{" "}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="w-100 row ml-0 "
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        className="col-5 d-flex align-items-center justify-content-start"
                        style={{ textAlign: "left" }}
                      >
                        <i
                          className="fas fa-clock fa-2x "
                          style={{ marginRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                        Mardi
                      </span>
                      <span className="col-7" style={{ textAlign: "left" }}>
                        09:00 à 17:00{" "}
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      className="w-100 row ml-0 "
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        className="col-5 d-flex align-items-center justify-content-start"
                        style={{ textAlign: "left" }}
                      >
                        <i
                          className="fas fa-clock fa-2x "
                          style={{ marginRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                        Mercredi
                      </span>
                      <span className="col-7" style={{ textAlign: "left" }}>
                        09:00 à 17:00{" "}
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      className="w-100 row ml-0 "
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        className="col-5 d-flex align-items-center justify-content-start"
                        style={{ textAlign: "left" }}
                      >
                        <i
                          className="fas fa-clock fa-2x "
                          style={{ marginRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                        Jeudi
                      </span>
                      <span className="col-7" style={{ textAlign: "left" }}>
                        09:00 à 17:00{" "}
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      className="w-100 row ml-0 "
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        className="col-5 d-flex align-items-center justify-content-start"
                        style={{ textAlign: "left" }}
                      >
                        <i
                          className="fas fa-clock fa-2x "
                          style={{ marginRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                        Vendredi
                      </span>
                      <span className="col-7" style={{ textAlign: "left" }}>
                        09:00 à 17:00{" "}
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      className="w-100 row ml-0 "
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        className="col-5 d-flex align-items-center justify-content-start"
                        style={{ textAlign: "left" }}
                      >
                        <i
                          className="fas fa-clock fa-2x "
                          style={{ marginRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                        Samedi
                      </span>
                      <span className="col-7" style={{ textAlign: "left" }}>
                        Fermé
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      className="w-100 row ml-0 "
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        className="col-5 d-flex align-items-center justify-content-start"
                        style={{ textAlign: "left" }}
                      >
                        <i
                          className="fas fa-clock fa-2x "
                          style={{ marginRight: "10px" }}
                          aria-hidden="true"
                        ></i>
                        Dimanche
                      </span>
                      <span className="col-7" style={{ textAlign: "left" }}>
                        Fermé
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-lg-3">
              <div className="footer_widget">
                <h3 className="footer_title">Liens Utiles</h3>
                <ul>
                  <li className="d-flex align-items-center justify-content-start">
                    <i className="fa fa-angle-right mr-2"></i>
                    <a
                      style={{ marginLeft: "5px", textDecoration: "none" }}
                      href="/"
                    >
                      Accueil
                    </a>
                  </li>
                  <li className="d-flex align-items-center justify-content-start">
                    <i className="fa fa-angle-right mr-2"></i>
                    <a
                      style={{ marginLeft: "5px", textDecoration: "none" }}
                      href="qui-sommes-nous/"
                    >
                      Qui somme-nous
                    </a>
                  </li>
                  <li className="d-flex align-items-center justify-content-start">
                    <i className="fa fa-angle-right mr-2"></i>
                    <a
                      style={{ marginLeft: "5px", textDecoration: "none" }}
                      href="nos-formations/"
                    >
                      Nos formations
                    </a>
                  </li>
                  <li className="d-flex align-items-center justify-content-start">
                    <i className="fa fa-angle-right mr-2"></i>
                    <a
                      style={{ marginLeft: "5px", textDecoration: "none" }}
                      href="/"
                    >
                      Calendrier
                    </a>
                  </li>
                  <li className="d-flex align-items-center justify-content-start">
                    <i className="fa fa-angle-right mr-2"></i>
                    <a
                      style={{ marginLeft: "5px", textDecoration: "none" }}
                      href="contact/"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-lg-12 mt-0 mt-lg-5 mt-xl-0">
              <div className="footer_widget">
                <h3 className="footer_title">Nous Contacter</h3>
                <HomeContactUsForm></HomeContactUsForm>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right_text">
        <div className="container">
          <div className="footer_border"></div>
          <div className="row">
            <div className="col-xl-12">
              <p className="copy_right text-center">
                Copyright &copy; 2024 <br></br>
                <strong style={{ marginRight: "5px" }}>1formationssiap</strong>
                Tous droits réservés.
                <a href="mentions-legales/" style={{ marginLeft: "10px" }}>
                  Mentions Légales
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default HomeFooter;
