import { combineReducers } from "redux";

// reducer import
import appReducer from "./App/appReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  app: appReducer,
});

export default reducer;
