import HomeOurFormations from "components/Home/NosFormations/homeOurFormations/HomeOurFormations";
import HomePresentation from "components/Home/homePresentation/HomePresentation";
import HomeSlider from "components/Home/homeSlider/HomeSlider";
import HomeWhyChooseUs from "components/Home/homeWhyChooseUs/HomeWhyChooseUs";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

export default () => {
  let navigate = useNavigate();
  return (
    <>
      <HomeSlider></HomeSlider>
      <AnimationRevealPage>
        <HomePresentation></HomePresentation>
        <HomeOurFormations></HomeOurFormations>
        <HomeWhyChooseUs></HomeWhyChooseUs>
      </AnimationRevealPage>
    </>
  );
};
