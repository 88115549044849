import React from "react";
import { Box, Typography } from "@mui/material";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { useState } from "react";

export default ({ icon, number, name }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust this threshold as needed
  });
  const [start, setStart] = useState(false);

  React.useEffect(() => {
    if (inView) {
      setStart(true);
    }
  }, [inView]);

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        color: "white",
      }}
    >
      <img
        src={icon ?? ""}
        alt=""
        style={{ height: "60px", width: "auto", marginBottom: "10px" }}
        loading="lazy"
      />
      <div>
        {start && (
          <CountUp start={0} end={number} duration={4} delay={0}>
            {({ countUpRef }) => (
              <Typography
                variant="h4"
                ref={countUpRef}
                style={{ marginBottom: "10px", display: "inline" }}
              ></Typography>
            )}
          </CountUp>
        )}

        <Typography
          variant="h4"
          style={{ marginBottom: "10px", display: "inline" }}
        >
          {["Réussite", "Taux de Satisfaction", "Taux d'interruption"].includes(
            name
          )
            ? "%"
            : name === "Stagiaires"
            ? "/sessions"
            : ""}
        </Typography>
      </div>
      <Typography variant="subtitle1">{name}</Typography>
    </Box>
  );
};
