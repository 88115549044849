import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import HomeOurFormations from "./homeOurFormations/HomeOurFormations";

export default () => {
  let navigate = useNavigate();

  return (
    <>
      <AnimationRevealPage>
        <h3 className="text-base font-semibold mb-3 text-center mt-4">
          DES FORMATIONS DE QUALITÉS ET ACCOMPAGNER PAR DES PROFESSIONELS
          CERTIFIER DANS LA SÉCURITÉ INCENDIE ET LA SECURITÉ PRIVÉE
        </h3>
        <HomeOurFormations></HomeOurFormations>
      </AnimationRevealPage>
    </>
  );
};
