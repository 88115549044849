import React, { useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./HomeOurFormations.css";
import { useSelector } from "react-redux";
import { BASE_URL_Image } from "services/server";

const formation1Image = BASE_URL_Image + "storage/images/formations/1.jpg";
const formation2Image = BASE_URL_Image + "storage/images/formations/2.jpg";
const formation3Image = BASE_URL_Image + "storage/images/formations/3.jpg";
const formation4Image = BASE_URL_Image + "storage/images/formations/4.jpg";
const formation1Icon =
  BASE_URL_Image + "storage/images/formations/coordinates.svg";
const formation2Icon =
  BASE_URL_Image + "storage/images/formations/security.svg";
const formation3Icon =
  BASE_URL_Image + "storage/images/formations/premiers-secours.svg";
const formation4Icon =
  BASE_URL_Image + "storage/images/formations/elecritque.png";

export default () => {
  let navigate = useNavigate();
  const [formations, setFormations] = useState([
    {
      id: 1,
      title: "Securité Incendie",
      image: formation1Image,
      icon: formation1Icon,
      action: "/nos-formations/securite-incendie",
    },
    {
      id: 2,
      title: "Securité Sureté",
      image: formation2Image,
      icon: formation2Icon,
      action: "/nos-formations/securite-surete",
    },
    {
      id: 3,
      title: "Secourisme",
      image: formation3Image,
      icon: formation3Icon,
      action: "/nos-formations/secourisme",
    },
    {
      id: 4,
      title: "Habilitation électrique",
      image: formation4Image,
      icon: formation4Icon,
      action: "/nos-formations/habilitations-electriques",
    },
  ]);
  return (
    <>
      <div id="aboutUs">
        <section
          className="pb-20 relative block "
          style={{ marginTop: "80px" }}
        >
          <div className="container-fluid mx-auto px-4 pt-4">
            <div className="flex flex-wrap items-center  my-3">
              <div className="w-full  px-4 mr-auto ml-auto  mb-5">
                <h3 className="text-3xl font-semibold mb-3 text-center">
                  NOS FORMATIONS
                </h3>
              </div>

              <div
                className="w-full  px-4 mr-auto ml-auto"
                style={{ maxWidth: "1000px" }}
              >
                <div className="w-100 row ml-0">
                  {formations.length > 0 ? (
                    <>
                      {formations.map((el, index) => {
                        return (
                          <div
                            key={index}
                            className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5"
                          >
                            <div className="w-100 bg-white formation-card">
                              <div
                                className="w-100 formation-card-media"
                                style={{
                                  backgroundImage: "url(" + el.image + ")",
                                }}
                              ></div>
                              <div className="w-100 formation-card-content">
                                <div className="w-100 d-flex align-items-center justify-content-center mb-2">
                                  <img
                                    alt="formation1_image "
                                    className="formation-card-icon"
                                    src={el.icon}
                                  />
                                </div>
                                <h3 className="text-base font-semibold mb-3 text-center">
                                  {el.title}
                                </h3>

                                <div className="w-100 d-flex align-items-center justify-content-end ">
                                  {/* <button
                                    className="w-100 btn btn-outline-primary formation-card-button"
                                    onClick={() => {
                                      navigate("/formation/" + el.id);
                                    }}
                                  >
                                    Consulter
                                  </button> */}
                                  <div
                                    className="light-color font-bold text-xs cursor-pointer"
                                    onClick={() => {
                                      navigate(el.action);
                                    }}
                                  >
                                    Consulter
                                    <i className="fa fa-angle-right ml-1"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
