import { Box, LinearProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { validatePhoneNumber } from "helpers/utils";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { toast } from "react-toastify";
// import { contactForm } from "services/visiteurService";

export default () => {
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [LastName, setLastName] = useState("");

  const contactForm = (info) => {};

  const { mutate: mutateSendEmail, isLoading: isLoadingSendEmail } =
    useMutation((formInfos) => contactForm(formInfos), {
      async onSuccess(data) {
        console.log(data);
        if (data && data.result) {
          notify_success("Votre message a été envoyé avec succès");
          setEmail("");
          setName("");
          setPhone("");
          setSubject("");
          setMessage("");
        } else {
          notify("Une erreur est survenue!!");
        }
      },
      onError(error) {},
    });

  const send = () => {
    var form = {
      name: Name,
      phone: Phone,
      email: Email,
      subject: Subject,
      message: Message,
    };
    console.log(form);
    if (!Phone || Phone === "" || !validatePhoneNumber(Phone)) {
      notify("Entrez votre numéro du téléphone s'il vous plaît !");
    } else if (!Name || Name === "") {
      notify("Entrez votre nom s'il vous plaît !");
    } else if (!LastName || LastName === "") {
      notify("Entrez votre prénom s'il vous plaît !");
    } else if (
      !Email ||
      Email === "" ||
      !String(Email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      notify("Entrez votre Email s'il vous plaît !");
    } else if (!Subject || Subject === "") {
      notify("Entrez votre sujet s'il vous plaît !");
    } else if (!Message || Message === "") {
      notify("Entrez votre message s'il vous plaît !");
    } else {
      mutateSendEmail(form);
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };
  return (
    <div className="flex-auto p-2">
      <div className="relative w-full mb-3 ">
        <label
          className="block uppercase text-white text-xs font-bold mb-2 w-100"
          style={{ textAlign: "left" }}
          htmlFor="full-name"
        >
          {"Numéro du téléphone"}
        </label>
        <input
          type="text"
          className="border-0 px-3 py-2 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  w-100"
          placeholder={"votre numéro du téléphone"}
          value={Phone}
          onChange={(e) => setPhone(e.currentTarget.value)}
        />
      </div>
      <div className="relative w-full mb-3 ">
        <label
          className="block uppercase text-white text-xs font-bold mb-2 w-100"
          style={{ textAlign: "left" }}
          htmlFor="full-name"
        >
          {"Nom"}
        </label>
        <input
          type="text"
          className="border-0 px-3 py-2 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  w-100"
          placeholder={"votre nom"}
          disabled={Phone === "" || !validatePhoneNumber(Phone)}
          value={Name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
      </div>
      <div className="relative w-full mb-3 ">
        <label
          className="block uppercase text-white text-xs font-bold mb-2 w-100"
          style={{ textAlign: "left" }}
          htmlFor="full-name"
        >
          {"Prénom"}
        </label>
        <input
          type="text"
          className="border-0 px-3 py-2 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  w-100"
          placeholder={"votre prénom"}
          disabled={Phone === "" || !validatePhoneNumber(Phone)}
          value={LastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
        />
      </div>

      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-white text-xs font-bold mb-2 w-100"
          style={{ textAlign: "left" }}
          htmlFor="email"
        >
          {"Email"}
        </label>
        <input
          type="email"
          className="border-0 px-3 py-2 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  w-100"
          placeholder={"votre email"}
          disabled={Phone === "" || !validatePhoneNumber(Phone)}
          value={Email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
      </div>

      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-white text-xs font-bold mb-2 w-100"
          style={{ textAlign: "left" }}
          htmlFor="email"
        >
          {"Sujet"}
        </label>
        <input
          type="email"
          className="border-0 px-3 py-2 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150  w-100"
          placeholder={"Sujet"}
          disabled={Phone === "" || !validatePhoneNumber(Phone)}
          value={Subject}
          onChange={(e) => setSubject(e.currentTarget.value)}
        />
      </div>

      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-white text-xs font-bold mb-2 w-100"
          style={{ textAlign: "left" }}
          htmlFor="message"
        >
          {"Message"}
        </label>
        <textarea
          rows="4"
          cols="80"
          className="border-0 px-3 py-2 placeholder-blueGray-600 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full w-100"
          placeholder={"votre message..."}
          disabled={Phone === "" || !validatePhoneNumber(Phone)}
          value={Message}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
      </div>
      <div className="text-center mt-6">
        <button
          className="bg-orange-500 text-white active:bg-orange-200 text-sm font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 w-100"
          type="button"
          onClick={send}
        >
          {"Envoyer"}
        </button>
      </div>

      <Modal
        show={isLoadingSendEmail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};
