export const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberPattern = /^(0[1-9]\d{8}|\+?[1-9]\d{1,14})$/;
  return phoneNumberPattern.test(phoneNumber);
};

export const validateEmail = (Email) => {
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailPattern.test(Email);
};
