import Title from "components/Typography/Title";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

export default ({ title, image, children }) => {
  let navigate = useNavigate();

  return (
    <>
      <section
        className="relative block py-24 lg:py-3 page_title_nos_formations"
        style={
          image
            ? {
                backgroundImage: "url(" + image + ")",
                backgroundSize: "cover",
                backgroundPosition: "center",
                paddingTop: "180px",
              }
            : { background: "#2c2c2c", paddingTop: "180px" }
        }
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center">
            <div className="w-full lg:w-10/12 px-4 mt-3">
              <div className="w-100">
                <Title>{title}</Title>
              </div>
              {children}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
