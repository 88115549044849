import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import AboutUsPresentation from "./aboutUsPresentation/AboutUsPresentation";
import AboutUsPoints from "./aboutUsPoints/AboutUsPoints";
import AnimationRevealPage from "helpers/AnimationRevealPage";

export default () => {
  let navigate = useNavigate();

  return (
    <>
      <AnimationRevealPage>
        <AboutUsPresentation></AboutUsPresentation>
        <AboutUsPoints></AboutUsPoints>
      </AnimationRevealPage>
    </>
  );
};
