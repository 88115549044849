import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

export default ({ metadata }) => {
  const [isClean, setIsClean] = useState(false);

  if (!metadata) return null;

  useEffect(() => {
    console.log("1. Début du nettoyage des meta tags spécifiques");

    // Remettre isClean à false à chaque changement de metadata
    setIsClean(false);

    // Liste des attributs à nettoyer (name ou property)
    const metaToClean = [
      "description",
      "og:title",
      "og:description",
      "og:site_name",
      "og:type",
      "og:locale",
      "twitter:card",
      "twitter:title",
      "twitter:description",
      "twitter:site",
      "article:publisher",
      "keywords",
    ];

    const head = document.head;
    const metaTags = head.getElementsByTagName("meta");

    Array.from(metaTags).forEach((meta) => {
      const name = meta.getAttribute("name");
      const property = meta.getAttribute("property");

      // Vérifie si cette meta tag doit être nettoyée
      if (metaToClean.includes(name) || metaToClean.includes(property)) {
        console.log(`Suppression de meta ${name || property}`);
        meta.remove();
      }
    });

    // Nettoyer uniquement le titre si on va le remplacer
    if (metadata.title || metadata.og?.title) {
      const existingTitle = document.querySelector("title");
      if (existingTitle) {
        existingTitle.remove();
      }
    }

    console.log("2. Fin du nettoyage des meta tags spécifiques");

    // Indiquer que le nettoyage est terminé
    setIsClean(true);
  }, [metadata]);

  console.log("3. Tentative de rendu des nouvelles meta tags", isClean);

  // Ne rendre les nouvelles meta tags que lorsque le nettoyage est terminé
  if (!isClean) {
    return null;
  }

  return (
    <Helmet>
      {(metadata.title || metadata.og?.title) && (
        <title>{metadata.title ?? metadata.og?.title}</title>
      )}

      {metadata.description && (
        <meta name="description" content={metadata.description} />
      )}

      {(metadata.og?.title || metadata.title) && (
        <meta
          property="og:title"
          content={metadata.og?.title || metadata.title}
        />
      )}

      {(metadata.og?.description || metadata.description) && (
        <meta
          property="og:description"
          content={metadata.og?.description || metadata.description}
        />
      )}

      {metadata.og && (
        <>
          {metadata.og.site_name && (
            <meta property="og:site_name" content={metadata.og.site_name} />
          )}
          {metadata.og.type && (
            <meta property="og:type" content={metadata.og.type ?? "article"} />
          )}
        </>
      )}

      <meta property="og:locale" content="fr_FR" />

      {(metadata.twitter?.card || "summary_large_image") && (
        <meta
          name="twitter:card"
          content={metadata.twitter?.card || "summary_large_image"}
        />
      )}

      {(metadata.twitter?.title || metadata.title) && (
        <meta
          name="twitter:title"
          content={metadata.twitter?.title || metadata.title}
        />
      )}

      {(metadata.twitter?.description || metadata.description) && (
        <meta
          name="twitter:description"
          content={metadata.twitter?.description || metadata.description}
        />
      )}

      <meta name="twitter:site" content="@1formationssiap" />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/profile.php?id=100068852185930"
      />

      {metadata.keywords && (
        <meta name="keywords" content={metadata.keywords} />
      )}
    </Helmet>
  );
};
