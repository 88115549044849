import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import aboutImage from "assets/images/about/happy.jpeg";
import "./AboutUsPoints.css";
import { useSelector } from "react-redux";

export default () => {
  let navigate = useNavigate();
  return (
    <>
      <div id="whyChooseUs">
        <section
          className="pb-20 relative block "
          style={{ paddingTop: "80px" }}
        >
          <div className="container-fluid mx-auto px-4 pt-4">
            <div className="flex flex-wrap items-center justify-content-center my-3">
              <div className="w-100 row " style={{ maxWidth: "1400px" }}>
                <div className="col-12  px-4 mr-auto ml-auto  mb-5">
                  <h3 className="text-3xl font-semibold mb-3 text-center">
                    Formation SST, SSIAP, Incendie et prévention
                  </h3>
                </div>
                <div className="col-12 col-md-6 p-0  m-0 d-flex align-items-center justify-content-center justify-content-md-end">
                  <img
                    alt="..."
                    className="max-w-full rounded-lg shadow-lg"
                    style={{ width: "80%", maxWidth: "600px", height: "auto" }}
                    src={aboutImage}
                  />
                </div>
                <div className="col-12 col-md-6 px-4 my-3 about_area d-flex align-items-center justify-content-center justify-content-md-start">
                  <div className="about_info">
                    <p className="text-base font-light leading-relaxed mt-4 mb-4 text-blueGray-600  text-left">
                      <b>ISIS Formation</b> (1formationSSIAP.fr ) est un centre
                      de formation spécialisé dans les métiers de la sécurité et
                      du secourisme. Nous formons depuis plus de 15 ans les
                      professionnels de grandes entreprises Françaises. Nos
                      formations sont réalisés par des professionnels du secteur
                      avec un seul objectif : votre réussite.
                    </p>
                    <ul>
                      <li>+ de 20 formations</li>
                      <li>Des formations sur mesure</li>
                      <li>Une organisation flexible</li>
                      <li>Une démarche qualité</li>
                      <li>Tout nos clients sont satisfaits</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
