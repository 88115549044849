import NosFormations from "components/Home/NosFormations/NosFormations";
import PageTitle from "components/Typography/PageTitle/PageTitle";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getOneFormationCatList } from "services/formationsService";
import { useMutation } from "@tanstack/react-query";
import CategoryListFormations from "components/Home/NosFormations/categoryListFormations/CategoryListFormations";
import Formation from "components/Home/NosFormations/Formation/Formation";
import { getFormation } from "services/formationsService";
import { BASE_URL_Image } from "services/server";

export default () => {
  let navigate = useNavigate();
  const { id_cat, id } = useParams();
  const [category, setCategory] = useState(null);
  const [formations, setFormations] = useState([]);
  const [formation, setFormation] = useState([]);

  const { mutate, isLoading } = useMutation(
    () => getFormation({ id_cat: id_cat, id: id }),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data && data.data.category) {
          setCategory(data.data.category);
          setFormations(data.data.formations);
          setFormation(data.data.formation);
        } else {
          navigate("/404");
        }
      },
      onError(error) {
        navigate("/404");
      },
    }
  );

  useEffect(() => {
    console.log(id);
    if (id && id != "" && id_cat && id_cat != "") {
      mutate();
    }
  }, [id, id_cat]);

  return (
    <>
      <PageTitle
        title={formation != null ? formation.titre_formation : ""}
        image={
          category != null && category.category_images.length > 0
            ? BASE_URL_Image + category.category_images[0]
            : null
        }
      >
        <div className="w-100 mt-5">
          <button
            className="btn btn-primary mx-3 mb-3 text-base"
            style={{ display: "inline-block", fontWeight: "500" }}
            onClick={() => {
              navigate("/nos-formations");
            }}
          >
            Consulter Les Formations
          </button>
          <button
            className="btn btn-outline-light mx-3 mb-3 text-base"
            style={{ display: "inline-block" }}
            onClick={() => {
              navigate("/reserver-des-formations");
            }}
          >
            <div className="w-100 d-flex align-items-center justify-content-start">
              <i
                class="fa fa-play"
                style={{ fontSize: "9px", marginRight: "9px" }}
              ></i>{" "}
              Reserver votre formation
            </div>
          </button>
        </div>
      </PageTitle>
      <Formation
        formation={formation}
        category={category}
        formations={formations}
      ></Formation>
    </>
  );
};
