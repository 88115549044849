import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme,
  LinearProgress,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { getFormationNames } from "services/formationsService";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { validatePhoneNumber } from "helpers/utils";
import { validateEmail } from "helpers/utils";
import { reservation } from "services/contactFormsService";
import { Modal } from "react-bootstrap";
import Loading from "components/loading/Loading";
import { askQuote } from "services/contactFormsService";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const QuoteForm = () => {
  const [formationsArray, setFormationsArray] = useState([]);
  const theme = useTheme();
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [formation, setFormation] = useState([]);
  const [centreFormation, setCentreFormation] = useState("");
  const [numberOfPeople, setNumberOfPeople] = useState(1);
  const [message, setMessage] = useState("");

  const handleFormationChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormation(typeof value === "string" ? value.split(",") : value);
  };

  const { mutate: mutateFormationsList, isLoading: isLoadingFormationsList } =
    useMutation(() => getFormationNames(), {
      async onSuccess(data) {
        console.log("getFormationNames");
        console.log(data);
        if (data && data.success) {
          setFormationsArray(data.data);
        } else {
          notify("Une erreur est survenue!!");
        }
      },
      onError(error) {},
    });

  useEffect(() => {
    mutateFormationsList();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Gérer la soumission du formulaire ici
    console.log({
      gender,
      firstname,
      lastname,
      company,
      email,
      website,
      address,
      address2,
      postcode,
      city,
      country,
      phone,
      formation,
      centreFormation,
      numberOfPeople,
      message,
    });
  };

  const { mutate: mutateSendEmail, isLoading: isLoadingSendEmail } =
    useMutation((formInfos) => askQuote(formInfos), {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          notify_success("Votre message a été envoyé avec succès");
          setEmail("");
          setFirstname("");
          setLastname("");
          setPhone("");
          setGender("");
          setCompany("");
          setAddress("");
          setAddress2("");
          setPostcode("");
          setCity("");
          setCountry("");
          setFormation([]);
          setNumberOfPeople(1);
          setMessage("");
        } else {
          notify("Une erreur est survenue!!");
        }
      },
      onError(error) {},
    });

  const send = () => {
    var form = {
      gender,
      firstname,
      lastname,
      society_name: company,
      email,
      website,
      address,
      address2,
      post_cod: postcode,
      city,
      country,
      phone,
      formation_choice: JSON.stringify(formation),
      centre_formation: centreFormation,
      nb_student: numberOfPeople,
      message,
    };
    console.log(form);
    if (!phone || phone === "" || !validatePhoneNumber(phone)) {
      notify("Entrez un numéro du téléphone valide s'il vous plaît !");
    } else if (!firstname || firstname === "") {
      notify("Entrez votre nom s'il vous plaît !");
    } else if (!lastname || lastname === "") {
      notify("Entrez votre prénom s'il vous plaît !");
    } else if (!gender || gender === "") {
      notify("Entrez votre genre s'il vous plaît !");
    } else if (!address || address === "") {
      notify("Entrez votre adresse s'il vous plaît !");
    } else if (!address2 || address2 === "") {
      notify("Entrez votre adresse complète s'il vous plaît !");
    } else if (!city || city === "") {
      notify("Entrez votre ville s'il vous plaît !");
    } else if (!formation || formation.length === 0) {
      notify("Entrez votre les formations souhaitées s'il vous plaît !");
    } else if (!centreFormation || centreFormation === "") {
      notify("Entrez votre le centre souhaité s'il vous plaît !");
    } else if (!email || email === "" || !validateEmail(email)) {
      notify("Entrez un Email valide s'il vous plaît !");
    } else {
      mutateSendEmail(form);
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel className="bg-white px-2">Genre</InputLabel>
            <Select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              disabled={phone === "" || !validatePhoneNumber(phone)}
            >
              <MenuItem value="Homme">Homme</MenuItem>
              <MenuItem value="Femme">Femme</MenuItem>
              <MenuItem value="Autre">Autre</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Nom"
            required
            fullWidth
            value={firstname}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setFirstname(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Prénom"
            required
            fullWidth
            value={lastname}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setLastname(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Société"
            fullWidth
            value={company}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setCompany(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="E-mail"
            required
            fullWidth
            type="email"
            value={email}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Site Web"
            fullWidth
            type="url"
            value={website}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Adresse"
            required
            fullWidth
            value={address}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Complément Adresse"
            fullWidth
            value={address2}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setAddress2(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Code Postal"
            required
            fullWidth
            value={postcode}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setPostcode(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Ville"
            required
            fullWidth
            value={city}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Pays"
            required
            fullWidth
            value={country}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setCountry(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Téléphone"
            required
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel className="bg-white px-2">
              Choisir une formation
            </InputLabel>
            <Select
              multiple
              value={formation}
              onChange={handleFormationChange}
              input={<OutlinedInput label="Formation" />}
              MenuProps={MenuProps}
              disabled={phone === "" || !validatePhoneNumber(phone)}
            >
              {formationsArray.map((form) => (
                <MenuItem
                  key={form.id}
                  value={form.nom_formation}
                  style={getStyles(form.nom_formation, formation, theme)}
                >
                  {form.nom_formation}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel className="bg-white px-2">
              Choisir le centre de formation
            </InputLabel>
            <Select
              value={centreFormation}
              disabled={phone === "" || !validatePhoneNumber(phone)}
              onChange={(e) => setCentreFormation(e.target.value)}
            >
              <MenuItem value="Paris">Centre de formation à Paris</MenuItem>
              <MenuItem value="Nanterre">
                Centre de formation à Nanterre
              </MenuItem>
              <MenuItem value="Lille">Centre de formation à Lille</MenuItem>
              <MenuItem value="Deplacement">Dans Mes Locaux</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nombre de Personnes"
            required
            fullWidth
            type="number"
            value={numberOfPeople}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setNumberOfPeople(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Message"
            multiline
            rows={4}
            fullWidth
            value={message}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <button
            className="btn btn-outline-primary mx-0 mb-3 text-base w-100"
            style={{ display: "inline-block", fontWeight: "500" }}
            onClick={send}
          >
            Envoyer ma demande
          </button>
        </Grid>

        {isLoadingFormationsList ? (
          <>
            <Loading></Loading>
          </>
        ) : (
          <></>
        )}

        <Modal
          show={isLoadingSendEmail}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="myModalLoading"
        >
          <Modal.Body style={{ overflow: "hidden" }}>
            <Box sx={{ width: "100%" }}>
              <LinearProgress color="secondary" />
            </Box>
          </Modal.Body>
        </Modal>
      </Grid>
    </Box>
  );
};

export default QuoteForm;
