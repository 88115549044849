import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, { useState } from "react";
import { List, ListItem, ListItemText, Paper, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Formation from "../Formation/Formation";

const StyledListItem = styled(ListItem)({
  position: "relative",
  "&:hover": {
    backgroundColor: "#82CDA1",
    color: "white",
    cursor: "pointer",
    "& .hoverMessage": {
      display: "block",
    },
  },
});

const HoverMessage = styled("div")({
  display: "none",
  position: "absolute",
  right: 10,
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  color: "white",
  padding: "5px 10px",
  borderRadius: "5px",
});

export default ({ formations, category }) => {
  let navigate = useNavigate();

  const capitalizeFirstLetterOfEachWord = (str) => {
    return str
      .split(/[\s-_]+/) // Split the string by spaces, hyphens, or underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" "); // Join all words together with a space
  };

  return (
    <AnimationRevealPage>
      <h2 className="text-lg font-semibold mb-3 text-center mt-4">
        Nos Formations De{" "}
        {category && category.category_name
          ? capitalizeFirstLetterOfEachWord(category.category_name)
          : ""}
      </h2>
      <div className="w-100 d-flex align-items-center justify-content-center">
        <div className="w-100" style={{ maxWidth: "900px" }}>
          {formations && formations.length ? (
            <>
              <List>
                {formations.map((f) => (
                  <StyledListItem
                    key={f.formation_id}
                    onClick={() => {}}
                    divider
                  >
                    <ListItemText
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      primary={f.nom_formation}
                    />
                    <HoverMessage
                      className="hoverMessage"
                      onClick={() => {
                        navigate(
                          "/nos-formations/" +
                            category.category_link +
                            "/" +
                            f.formation_link
                        );
                      }}
                    >
                      Consulter
                    </HoverMessage>
                  </StyledListItem>
                ))}
              </List>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </AnimationRevealPage>
  );
};
