import React from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import KPIComponent2 from "./KPIComponent2";

const BoxBefor = styled(Box)({
  backgroundSize: "cover",
  backgroundPosition: "center",
  padding: 0,
  minHeight: "250px",
  position: "relative",
  zIndex: 0,
});

export default ({ kpis }) => {
  return (
    <BoxBefor className=" mt-5 kpis_list d-flex align-items-center justify-content-center">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ maxWidth: "1200px" }}
      >
        {kpis.map((kpi, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <KPIComponent2
              extra={kpi.extra}
              number={kpi.number}
              name={kpi.name}
            />
          </Grid>
        ))}
      </Grid>
    </BoxBefor>
  );
};
