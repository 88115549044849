// src/hooks/useMetadata.js
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { authApi } from "services/server";
import { Helmet } from "react-helmet";

export const useMetadata = () => {
  const location = useLocation();
  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        setLoading(true);

        // Vérifier si le chemin commence par /nos-formations/
        if (!location.pathname.startsWith("/nos-formations/")) {
          // Si ce n'est pas une route de formation, récupérer les métadonnées normalement
          const response = await authApi.get(`metadata${location.pathname}`);
          setMetadata(response.data);
          return;
        }

        // Découper le chemin pour analyser la structure
        const parts = location.pathname.split("/").filter(Boolean);

        // Si c'est juste /nos-formations/ ou /nos-formations
        if (parts.length <= 1) {
          const response = await authApi.get(`metadata${location.pathname}`);
          setMetadata(response.data);
          return;
        }

        // Si c'est une catégorie (/nos-formations/[categorie])
        if (parts.length === 2) {
          // Ne rien faire pour les catégories
          return;
        }

        // Si c'est une formation (/nos-formations/[categorie]/[formation])
        if (parts.length === 3) {
          // Ne rien faire pour les formations
          return;
        }
      } catch (err) {
        console.error("Erreur lors de la récupération des métadonnées:", err);
        setError(err);
        // Métadonnées par défaut en cas d'erreur
        setMetadata({
          title: "ISIS Formation",
          description: "Centre de formation SSIAP Paris - Ile de France",
          og: {
            title: "ISIS Formation",
            description: "Centre de formation SSIAP Paris - Ile de France",
            site_name: "ISIS Formation",
            type: "website",
          },
        });
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
  }, [location.pathname]);

  return { metadata, loading, error };
};
