import { authApi } from "./server";

export const sendContactForm = async (userInfo) => {
  const response = await authApi.post("sendContactForm", userInfo);
  return response.data;
};

export const becomePartner = async (userInfo) => {
  const response = await authApi.post("becomePartner/", userInfo);
  return response.data;
};

export const askQuote = async (userInfo) => {
  const response = await authApi.post("askQuote/", userInfo);
  return response.data;
};

export const reservation = async (userInfo) => {
  const response = await authApi.post("reservation/", userInfo);
  return response.data;
};
