import { authApi } from "./server";

export const getFormationCatsList = async (userInfo) => {
  const response = await authApi.post("formations/categories", userInfo);
  return response.data;
};

export const getOneFormationCatList = async (userInfo) => {
  const response = await authApi.post("formations/getOneFormationCatList", {
    id: userInfo,
  });
  return response.data;
};

export const getFormation = async (userInfo) => {
  const response = await authApi.post("formations/getFormation", userInfo);
  return response.data;
};

export const getFormationNames = async () => {
  const response = await authApi.post("formations/getFormationNames", {});
  return response.data;
};
