import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  Typography,
  Link,
  Box,
  Divider,
} from "@mui/material";
import { fontWeight, styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useEffect } from "react";
import { BASE_URL_Image } from "services/server";
const ImageFormation =
  BASE_URL_Image + "storage/images/formations/1formationssiap_logo.gif";
const ExpandMore = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "expand",
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
}));

export default ({ formation }) => {
  const [expandedPrice, setExpandedPrice] = useState(true);

  const handleExpandPriceClick = () => {
    setExpandedPrice(!expandedPrice);
  };
  const [expandedLocation, setExpandedLocation] = useState(false);
  const handleExpandLocationClick = () => {
    setExpandedLocation(!expandedLocation);
  };

  const [expandedCalender, setExpandedCalender] = useState(false);
  const handleExpandCalenderClick = () => {
    setExpandedCalender(!expandedCalender);
  };

  useEffect(() => {
    console.log("formation");
    console.log(formation);
  }, [formation]);

  return (
    <Card elevation={3} sx={{ maxWidth: 450, margin: "20px auto" }}>
      <CardMedia
        component="img"
        height="200"
        image={ImageFormation}
        alt={"ImageFormation"}
      />

      <CardContent>
        <Box mb={2}>
          <Typography variant="subtitle1" component="div" align="center">
            <strong>1formationssiap</strong>
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="subtitle1" component="div" align="center">
            Centre de formation
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary" align="center">
            42 rue Le Peletier 75009 Paris
          </Typography>
        </Box>
        <Box mb={2}>
          <Divider></Divider>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1" color="div" align="center">
            Accès via:
            <br />
          </Typography>
          <ul style={{ listStyle: "inside" }}>
            <li>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                style={{ width: "max-content", display: "inline" }}
              >
                <b style={{ color: "#f59bbb" }}>Ligne M7: Le Peletier</b> <br />
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                style={{ width: "max-content", display: "inline" }}
              >
                <b style={{ color: "#007852" }}>
                  Ligne M12: Notre Dame de Lorette
                </b>
              </Typography>
            </li>
          </ul>
        </Box>
        <Box mb={2}>
          <Divider></Divider>
        </Box>
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary" align="center">
            Lundi au Vendredi de{" "}
            <spam style={{ color: "#ff7300", fontWeight: "bold" }}>
              8H00 à 17H00
            </spam>
          </Typography>
        </Box>
        <Box mb={2}>
          <Divider></Divider>
        </Box>
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary" align="center">
            <Link href="tel:+33143494022" style={{ textDecoration: "none" }}>
              <b style={{ color: "#28ae60" }}>01 43 49 40 22</b>
            </Link>
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary" align="center">
            <Link
              href="mailto:contact@1formationssiap.fr"
              style={{ textDecoration: "none" }}
            >
              <b style={{ color: "#28ae60" }}>contact@1formationssiap.fr</b>
            </Link>
          </Typography>
        </Box>
        <Box mb={2}>
          <Divider></Divider>
        </Box>
      </CardContent>
      {/* <CardHeader
        title={title}
        subheader={
          <>
            <Typography variant="body2" color="textSecondary">
              <LocationOnIcon fontSize="small" /> {location}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <PhoneIcon fontSize="small" /> {phone}
            </Typography>
          </>
        }
      /> */}
      <CardActions
        disableSpacing
        onClick={handleExpandPriceClick}
        style={{
          cursor: "pointer",
          backgroundColor: expandedPrice ? "#28ae60" : "",
          color: expandedPrice ? "white !important" : "",
        }}
      >
        <Typography
          variant="subtitle1"
          color={expandedPrice ? "HighlightText" : "textSecondary"}
        >
          <strong>
            {formation && formation.tarif > 0
              ? "Tarif : " + formation.tarif + " € NET"
              : "Devis Gratuit"}{" "}
          </strong>
        </Typography>
        <ExpandMore
          expand={expandedPrice}
          aria-expanded={expandedPrice}
          aria-label="show more"
        >
          <ExpandMoreIcon style={{ color: expandedPrice ? "white" : "" }} />
        </ExpandMore>
      </CardActions>
      <Collapse in={expandedPrice} timeout="auto" unmountOnExit>
        <CardContent>
          {formation.id_formation === "sst-sauveteur-secouriste-du-travail" ? (
            <></>
          ) : (
            <Box mb={1}>
              <Typography variant="subtitle1" component="div" align="left">
                <b> Cette formation est éligible au CPF</b>
              </Typography>
            </Box>
          )}
          <Box mb={2}>
            <Typography variant="body2" color="textSecondary" align="left">
              Pour plus d'information veillez nous{" "}
              <Link onClick={() => {}} style={{ textDecoration: "none" }}>
                <b style={{ color: "#28ae60" }}>contactez</b>
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Collapse>

      <CardActions
        disableSpacing
        onClick={handleExpandLocationClick}
        style={{
          cursor: "pointer",
          backgroundColor: expandedLocation ? "#28ae60" : "",
          color: expandedLocation ? "white !important" : "",
        }}
      >
        <Typography
          variant="subtitle1"
          color={expandedLocation ? "HighlightText" : "textSecondary"}
        >
          <strong>Lieu : Paris et Nanterre</strong>
        </Typography>
        <ExpandMore
          expand={expandedLocation}
          aria-expanded={expandedLocation}
          aria-label="show more"
        >
          <ExpandMoreIcon style={{ color: expandedLocation ? "white" : "" }} />
        </ExpandMore>
      </CardActions>
      <Collapse in={expandedLocation} timeout="auto" unmountOnExit>
        <CardContent>
          <Box mb={1}>
            <Typography variant="subtitle1" component="div" align="left">
              <b style={{ color: "black" }}>42 rue Le Peletier 75009 Paris</b>
            </Typography>
          </Box>
        </CardContent>
      </Collapse>

      <CardActions
        disableSpacing
        onClick={handleExpandCalenderClick}
        style={{
          cursor: "pointer",
          backgroundColor: expandedCalender ? "#28ae60" : "",
          color: expandedCalender ? "white !important" : "",
        }}
      >
        <Typography
          variant="subtitle1"
          color={expandedCalender ? "HighlightText" : "textSecondary"}
        >
          <strong>Calendrier des formations</strong>
        </Typography>
        <ExpandMore
          expand={expandedCalender}
          aria-expanded={expandedCalender}
          aria-label="show more"
        >
          <ExpandMoreIcon style={{ color: expandedCalender ? "white" : "" }} />
        </ExpandMore>
      </CardActions>
      <Collapse in={expandedCalender} timeout="auto" unmountOnExit>
        <CardContent>
          <Box mb={2}>
            <Typography variant="body2" color="textSecondary" align="left">
              Pour voir le calendrier{" "}
              <Link onClick={() => {}} style={{ textDecoration: "none" }}>
                <b style={{ color: "#28ae60" }}>cliquez ici</b>
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};
