// src/hooks/useStatistics.js
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { authApi } from "services/server";

export const useStatistics = () => {
  const location = useLocation();
  const startTimeRef = useRef(new Date());
  const [sessionId, setSessionId] = useState(null);
  const previousPathRef = useRef(location.pathname);

  // Fonction pour envoyer les événements à GA4

  // const sendToGA = (eventName, eventParams) => {
  //   if (typeof window.gtag !== "undefined") {
  //     console.log("GA Event:", eventName, eventParams); // Debug log
  //     window.gtag("event", eventName, eventParams);
  //   } else {
  //     console.warn("Google Analytics not loaded");
  //   }
  // };

  const sendToGA = (eventName, eventParams) => {
    if (typeof window.gtag !== "undefined") {
      window.gtag("event", eventName, eventParams);
    }
  };

  // Effet pour gérer le changement de route
  useEffect(() => {
    // Mettre à jour la durée de la page précédente
    if (sessionId && previousPathRef.current) {
      const endTime = new Date();
      authApi
        .post("storeDuration", {
          duration: endTime - startTimeRef.current,
          id_session: sessionId,
          id_page: previousPathRef.current, // Utiliser l'ancien pathname pour la durée
        })
        .catch((error) => {
          console.error("Erreur lors de la mise à jour de la durée:", error);
        });

      // Envoyer la durée à GA4
      sendToGA("page_engagement", {
        page_path: previousPathRef.current,
        engagement_time_msec: endTime - startTimeRef.current,
        session_id: sessionId,
      });
      // Réinitialiser le temps de départ pour la nouvelle page
      startTimeRef.current = endTime;
    }

    previousPathRef.current = location.pathname;

    // Si on a déjà une session, enregistrer les stats pour la nouvelle page
    if (sessionId) {
      getGeoData(location.pathname);
    }
  }, [location.pathname]);

  const getOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    }

    return os || "Unknown";
  };

  // Récupérer l'ID de session au chargement
  useEffect(() => {
    const fetchSessionId = async () => {
      try {
        const response = await authApi.get("getSessionId");
        const id = response.data.session_id;
        setSessionId(id);
        sessionStorage.setItem("session", id);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'ID de session:",
          error
        );
      }
    };

    fetchSessionId();
  }, []);

  const saveStatistics = async (geoData, currentPath) => {
    try {
      await authApi.post("statistics", {
        os: getOS(),
        country_code: geoData.countryCode,
        country_name: geoData.country,
        city: geoData.city,
        postal: geoData.zip,
        latitude: geoData.lat,
        longitude: geoData.lon,
        IPv4: geoData.query,
        state: geoData.regionName,
        duration: "",
        id_session: sessionId,
        id_page: currentPath, // Utiliser le pathname actuel
      });

      // Envoyer les données à GA4
      sendToGA("page_view", {
        page_location: window.location.href,
        page_path: currentPath,
        page_title: document.title,
        session_id: sessionId,
      });

      // Envoyer les données utilisateur à GA4
      sendToGA("user_data", {
        operating_system: getOS(),
        country: geoData.country,
        region: geoData.regionName,
        city: geoData.city,
        session_id: sessionId,
        user_properties: {
          operating_system: getOS(),
          country: geoData.country,
          city: geoData.city,
        },
      });

      // Envoyer les données de localisation à GA4
      sendToGA("location_data", {
        country: geoData.country,
        region: geoData.regionName,
        city: geoData.city,
        postal_code: geoData.zip,
        latitude: geoData.lat,
        longitude: geoData.lon,
        session_id: sessionId,
      });
    } catch (error) {
      console.error("Erreur lors de l'enregistrement des statistiques:", error);
    }
  };

  const getGeoData = async (currentPath) => {
    try {
      const cachedData = localStorage.getItem("geoData");
      const cacheTime = localStorage.getItem("geoDataTime");
      const now = new Date().getTime();

      if (
        cachedData &&
        cacheTime &&
        now - parseInt(cacheTime) < 24 * 60 * 60 * 1000
      ) {
        await saveStatistics(JSON.parse(cachedData), currentPath);
        return;
      }

      const response = await axios.get("http://ip-api.com/json");

      localStorage.setItem("geoData", JSON.stringify(response.data));
      localStorage.setItem("geoDataTime", now.toString());

      await saveStatistics(response.data, currentPath);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données géographiques:",
        error
      );
      await saveStatistics(
        {
          countryCode: "N/A",
          country: "Unknown",
          city: "Unknown",
          zip: "N/A",
          lat: 0,
          lon: 0,
          query: "N/A",
          regionName: "Unknown",
        },
        currentPath
      );

      // Envoyer l'erreur à GA4
      sendToGA("error", {
        error_type: "geo_data_fetch_failed",
        error_message: error.message,
        session_id: sessionId,
      });
    }
  };

  // Enregistrer les statistiques initiales
  useEffect(() => {
    if (!sessionId) return;
    getGeoData(location.pathname);
  }, [sessionId]);

  // Cleanup lors du démontage final
  useEffect(() => {
    return () => {
      if (sessionId) {
        const endTime = new Date();
        authApi
          .post("storeDuration", {
            duration: endTime - startTimeRef.current,
            id_session: sessionId,
            id_page: location.pathname, // Utiliser le pathname actuel
          })
          .catch((error) => {
            console.error("Erreur lors de la mise à jour de la durée:", error);
          });

        // Envoyer la durée finale à GA4
        sendToGA("page_engagement", {
          page_path: location.pathname,
          engagement_time_msec: endTime - startTimeRef.current,
          session_id: sessionId,
          final_page_view: true,
        });
      }
    };
  }, [sessionId, location.pathname]);

  return null;
};
