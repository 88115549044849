import PageTitle from "components/Typography/PageTitle/PageTitle";
import React from "react";
import { Link } from "react-router-dom";

const Page403 = () => {
  return (
    <>
      <PageTitle title={""}></PageTitle>
      <div className="container text-center my-5">
        <h1 className="display-1 text-warning">403</h1>
        <p className="lead">
          Désolé, vous n'avez pas la permission d'accéder à cette page.
        </p>
        <Link to="/" className="btn btn-primary">
          Retour à l'accueil
        </Link>
      </div>
    </>
  );
};

export default Page403;
