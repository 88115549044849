import NosFormations from "components/Home/NosFormations/NosFormations";
import PageTitle from "components/Typography/PageTitle/PageTitle";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getOneFormationCatList } from "services/formationsService";
import { useMutation } from "@tanstack/react-query";
import CategoryListFormations from "components/Home/NosFormations/categoryListFormations/CategoryListFormations";
import { BASE_URL_Image } from "services/server";
import SEO from "components/SEO/SEO";

export default () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState(null);
  const [formations, setFormations] = useState([]);

  const { mutate, isLoading } = useMutation(
    (id) => getOneFormationCatList(id),
    {
      async onSuccess(data) {
        console.log(data);
        if (data && data.data && data.data.category) {
          setCategory(data.data.category);
          setFormations(data.data.formations);
        }
      },
      onError(error) {},
    }
  );

  useEffect(() => {
    mutate(id);
  }, [id]);

  return (
    <>
      <PageTitle
        title={category != null ? category.category_name : ""}
        image={
          category != null && category.category_images.length > 0
            ? BASE_URL_Image + category.category_images[0]
            : null
        }
      >
        <div className="w-100 mt-5">
          <button
            className="btn btn-primary mx-3 mb-3 text-base"
            style={{ display: "inline-block", fontWeight: "500" }}
            onClick={() => {
              navigate("/nos-formations");
            }}
          >
            Consulter Les Formations
          </button>
          <button
            className="btn btn-outline-light mx-3 mb-3 text-base"
            style={{ display: "inline-block" }}
            onClick={() => {
              navigate("/reserver-des-formations");
            }}
          >
            <div className="w-100 d-flex align-items-center justify-content-start">
              <i
                class="fa fa-play"
                style={{ fontSize: "9px", marginRight: "9px" }}
              ></i>{" "}
              Reserver votre formation
            </div>
          </button>
        </div>
      </PageTitle>
      {category && category.metadata ? (
        <>
          <SEO metadata={category.metadata} />
        </>
      ) : (
        <></>
      )}
      <CategoryListFormations
        formations={formations}
        category={category}
      ></CategoryListFormations>
    </>
  );
};
