import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import aboutImage from "assets/images/about/about.jpg";
import "./HomePresentation.css";
import { useSelector } from "react-redux";
export default () => {
  let navigate = useNavigate();
  return (
    <>
      <div id="aboutUs">
        <section
          className="pb-20 relative block "
          style={{ paddingTop: "80px" }}
        >
          <div className="container-fluid mx-auto px-4 pt-4">
            <div className="flex flex-wrap items-center">
              <div className="w-full md:w-6/12 p-0 mr-auto ml-auto m-0 d-flex align-items-center justify-content-center">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  style={{ width: "100%", height: "auto" }}
                  src={aboutImage}
                />
              </div>
              <div className="w-full md:w-5/12 px-4 my-3 mr-auto ml-auto">
                <h3 className="text-3xl font-semibold mb-3 text-left">
                  ISIS Formation – centre de formation aux métiers de la
                  sécurité incendie
                </h3>
                <p className="text-base font-light leading-relaxed mt-4 mb-4 text-blueGray-600  text-left">
                  <b>ISIS Formation</b> est spécialisé dans la formation des
                  secteurs de la sécurité incendie (<b>ssiap1</b>, <b>ssiap2</b>
                  , <b>ssiap3</b>...) et de la sûreté.
                  <br />
                  <br />
                  Notre objectif est de vous former,{" "}
                  <b>vous serez sensibilisé sur les dangers</b> de l’incendie,
                  de la sureté et vous apprendrez{" "}
                  <b>les réflexes de secourisme</b>, utiles lors des accidents
                  du travail.
                  <br />
                  <br />
                  Grâce à nos fortes années d’expériences auprès de nombreux
                  acteurs du marché de la Sureté et de la Sécurité, nous avons
                  construit des liens de confiance nous permettant d’offrir à
                  nos stagiaires
                  <b>de réelles perspectives d’embauche</b>.
                </p>
                <div className="w-100 d-flex align-items-center justify-content-start ">
                  <Button
                    variant="contained"
                    color="success"
                    className="w-100"
                    style={{
                      fontSize: "14px",
                      maxWidth: "250px",
                      minHeight: "50px",
                    }}
                    onClick={() => {
                      navigate("/qui-sommes-nous");
                    }}
                  >
                    À Propos de nous
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
