import PageTitle from "components/Typography/PageTitle/PageTitle";
import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <>
      <PageTitle title={""}></PageTitle>
      <div className="container text-center my-5">
        <h1 className="display-1 text-danger">404</h1>
        <p className="lead">Oups ! La page que vous recherchez n'existe pas.</p>
        <Link to="/" className="btn btn-primary">
          Retour à l'accueil
        </Link>
      </div>
    </>
  );
};

export default Page404;
