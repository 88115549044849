import PageTitle from "components/Typography/PageTitle/PageTitle";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const MentionsLegales = () => {
  let navigate = useNavigate();
  return (
    <>
      <PageTitle title={"Mentions Légales"}>
        <div className="w-100 mt-5">
          <button
            className="btn btn-primary mx-3 mb-3 text-base"
            style={{ display: "inline-block", fontWeight: "500" }}
            onClick={() => {
              navigate("/contact");
            }}
          >
            Nous Contacter
          </button>
        </div>
      </PageTitle>

      <div className="container my-5">
        <div className="text-center mb-4">
          <h3>Nos mentions légales</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <article>
              <Section>
                <p>
                  Merci de lire attentivement les présentes modalités
                  d’utilisation du présent site avant de le parcourir. En vous
                  connectant sur ce site, vous acceptez sans réserve les
                  présentes modalités.
                </p>
              </Section>

              <Section title="Editeur du site">
                <ContactInfo
                  name="ISIS Formation"
                  address="42 rue Le Peletier"
                  city="75009 Paris"
                  country="France"
                  phone="+33 (0)1 43 49 40 22"
                />
                <p>
                  ISIS Formation est une SARL au capital de 7622.45€
                  <br />
                  RCS B 428 686 513 – Siret : 42868651300030 – APE : 8010Z
                  <br />
                  N° déclaration CNIL : en cours
                </p>
                <p>
                  DIRECTE: <strong>11930675493</strong>
                </p>
                <p>
                  SSIAP: <strong>2015-0016-093</strong>
                </p>
                <p>
                  SST: <strong>1204018/2017/SST-01/O/12</strong>
                </p>
              </Section>

              <Section title="Conditions d’utilisation">
                <p>
                  Le site accessible par les URL suivants :
                  <a href="https://www.1formationssiap.fr">
                    www.1formationssiap.fr
                  </a>{" "}
                  est exploité dans le respect de la législation française.
                  L’utilisation de ce site est régie par les présentes
                  conditions générales. En utilisant le site, vous reconnaissez
                  avoir pris connaissance de ces conditions et les avoir
                  acceptées. Celles-ci pourront être modifiées à tout moment et
                  sans préavis par la société ISIS Formation.
                </p>
                <p>
                  ISIS Formation ne saurait être tenu pour responsable en aucune
                  manière d’une mauvaise utilisation du service.
                </p>
              </Section>

              <Section title="Responsable éditorial">
                <ContactInfo
                  name="1formationssiap.fr"
                  address="42 rue Le Peletier"
                  city="75009 Paris"
                  country="France"
                  phone="+33 (0)1 43 49 40 22"
                />
              </Section>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

const Section = ({ title, children }) => (
  <div className="mb-4">
    {title && <h3 className="mb-3">{title}</h3>}
    {children}
  </div>
);

const ContactInfo = ({ name, address, city, country, phone }) => (
  <address className="mb-4">
    <p>
      <strong>{name}</strong>
      <br />
      {address}
      <br />
      {city}
      <br />
      <span>{country}</span>
      <br />
      <span>
        <strong>Tél. :</strong> {phone}
      </span>
    </p>
  </address>
);

export default MentionsLegales;
