import { useEffect } from "react";
import { Navbar, Nav, NavDropdown, Button, Modal } from "react-bootstrap";
import * as $ from "jquery";

import logo from "assets/images/logo/logo-off.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RegistrationForm from "components/Contact/Form/RegistrationForm";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";

function NavbarSecond(props) {
  let navigate = useNavigate();
  const location = useLocation();

  const [showReservation, setShowReservation] = useState(false);

  const handleClose = () => {
    setShowReservation(false);
  };

  // const { mutate: mutateInsertFormation, isLoading: isLoadingInsertFormation } =
  //   useMutation(() => insertFormations(), {
  //     async onSuccess(data) {
  //       console.log(data);
  //       if (data && data.success) {
  //       } else {
  //       }
  //     },
  //     onError(error) {},
  //   });

  const ajustePage = () => {
    var height = $(".top-header").innerHeight();
    var navigationH = $(".navigation").innerHeight();

    // if (location.pathname !== "/" && location.pathname !== "/nos-formations") {
    // }
    $(".navigation").addClass("nav-bg");

    if ($("header").offset().top > 10) {
      $(".top-header").addClass("hide");
      // if (
      //   location.pathname === "/" ||
      //   location.pathname === "/nos-formations"
      // ) {
      // }
      $(".navigation").addClass("nav-bg");
      $(".navigation").css("margin-top", "-" + height + "px");
    } else {
      $(".top-header").removeClass("hide");
      // if (
      //   location.pathname === "/" ||
      //   location.pathname === "/nos-formations"
      // ) {
      // }
      $(".navigation").removeClass("nav-bg");
      $(".navigation").css("margin-top", "-" + 0 + "px");
    }
    if ($(window).width() >= 992) {
      $(".dropdown-menu-formations , .dropdown-menu-contact").css(
        "top",
        navigationH + 3 + "px"
      );
    } else {
      $(".dropdown-menu-formations , .dropdown-menu-contact").css(
        "top",
        " 0 px"
      );
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(location.pathname);
    $(document).ready(ajustePage);

    $(window).on("scroll", ajustePage);

    return () => {
      $(window).off("scroll", ajustePage);
    };
  }, [location]);
  return (
    <header className="fixed-top header">
      {/* top header */}
      <div className="top-header px-2 py-0 bg-white">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-4 text-center text-lg-left top-header-first">
              <ul className="list-inline d-inline m-0">
                <li className="list-inline-item mx-0">
                  <a
                    className="d-inline-block p-2 text-color"
                    href="https://www.facebook.com/profile.php?id=100068852185930"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item mx-0">
                  <a
                    className="d-inline-block p-2 text-color"
                    href="https://twitter.com/1Ssiap"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item mx-0">
                  <a
                    className="d-inline-block p-2 text-color"
                    href="https://www.linkedin.com/in/1formationssiap-1formationssiap-b65922211/"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-8 text-center text-lg-right d-flex align-items-center justify-content-center top-header-second">
              <ul className="list-inline m-0">
                <li className="list-inline-item mx-3">
                  <a
                    className="text-color mr-3"
                    href="tel:+443003030266"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fa fa-phone" style={{ color: "#28AE60" }}></i>{" "}
                    01 43 49 40 22
                  </a>
                </li>
                <li className="list-inline-item mx-3">
                  <a
                    className="text-color mr-3"
                    href="mailto:+443003030266"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fa fa-envelope"></i>{" "}
                    contact@isis-formation.net
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* navbar */}
      <div className="navigation w-100">
        <div className="container">
          <Navbar expand="lg" variant="dark" className="p-0">
            <Navbar.Brand
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={logo} alt="logo" style={{ height: "50px" }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navigation" />
            <Navbar.Collapse
              id="navigation"
              className="justify-content-between"
            >
              <Nav className="justify-content-center col">
                {props.navItems != null && props.navItems.length > 0 ? (
                  <>
                    {props.navItems.map((el, index) => {
                      if (el.dropdown) {
                        return (
                          <li key={index} className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              id={"navbarDropdown" + index}
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {el.title}
                            </a>
                            <ul
                              className={"dropdown-menu " + el.ulClassName}
                              aria-labelledby={"navbarDropdown" + index}
                            >
                              {el.row ? (
                                <>
                                  <PerfectScrollbar
                                    component="div"
                                    style={{ maxHeight: "60vh" }}
                                  >
                                    <Grid container spacing={3} sx={{ p: 3 }}>
                                      {el.items.map((el2, index2) => {
                                        return (
                                          <Grid
                                            key={index2}
                                            item
                                            xs={el2.xs}
                                            md={el2.md}
                                          >
                                            {el2.items.map((el3, index3) => {
                                              return (
                                                <>
                                                  <div
                                                    className="title"
                                                    style={{
                                                      fontWeight: "bold",
                                                      fontSize: "14px",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      navigate(el3.link);
                                                    }}
                                                  >
                                                    {el3.title}
                                                  </div>
                                                  {el3.items.map(
                                                    (el4, index4) => {
                                                      return (
                                                        <li key={index4}>
                                                          <a
                                                            className="dropdown-item"
                                                            onClick={() => {
                                                              navigate(
                                                                el4.link
                                                              );
                                                            }}
                                                          >
                                                            {el4.title}
                                                          </a>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              );
                                            })}
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </PerfectScrollbar>
                                </>
                              ) : (
                                <>
                                  {el.items.map((el2, index2) => {
                                    return (
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => {
                                            navigate(el2.link);
                                          }}
                                        >
                                          {el2.title}
                                        </a>
                                      </li>
                                    );
                                  })}
                                </>
                              )}
                            </ul>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            className={
                              "nav-item " +
                              (el.link === location.pathname ? "active" : "")
                            }
                          >
                            <a
                              className="nav-link"
                              onClick={() => {
                                navigate(el.link);
                              }}
                            >
                              {el.title}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </>
                ) : (
                  <></>
                )}
              </Nav>

              <Nav className="justify-content-center">
                <Button
                  variant="success"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    setShowReservation(true);
                    // if (!isLoadingInsertFormation) {
                    //   mutateInsertFormation();
                    // }
                  }}
                >
                  Reserver
                </Button>
                {/* <li className="nav-item @@about">
                </li> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>

      <Modal
        show={showReservation}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>INSCRIVEZ VOUS EN LIGNE</Modal.Header>
        <Modal.Body className="px-5 py-5">
          <RegistrationForm callback={handleClose}></RegistrationForm>
        </Modal.Body>
      </Modal>
    </header>
  );
}

export default NavbarSecond;
