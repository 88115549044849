import React from "react";
import { Box, Grid } from "@mui/material";
import KPIComponent from "./KPIComponent";
import { styled } from "@mui/system";
import { BASE_URL_Image } from "services/server";

const ImageKPIs = BASE_URL_Image + "storage/images/formations/carousel-2.jpg";
const BoxBefor = styled(Box)({
  backgroundSize: "cover",
  backgroundPosition: "center",
  padding: 0,
  minHeight: "250px",
  position: "relative",
  zIndex: 0,

  "&::before": {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "#2C2C2C",
    opacity: 0.6,
    content: '""',
    zIndex: "-1",
  },
});

export default ({ kpis }) => {
  return (
    <BoxBefor
      className=" mt-5 kpis_list"
      sx={{
        backgroundImage: "url(" + ImageKPIs + ")",
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {kpis.map((kpi, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <KPIComponent icon={kpi.icon} number={kpi.number} name={kpi.name} />
          </Grid>
        ))}
      </Grid>
    </BoxBefor>
  );
};
