import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import FormationCard from "../FormationCard/FormationCard";
import KPIListComponent from "components/KPI/KPIListComponent";

import SchoolIcon from "@mui/icons-material/School";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WarningIcon from "@mui/icons-material/Warning";

import { useEffect } from "react";
import KPIListComponent2 from "components/KPI2/KPIListComponent2";
import CategoryListFormations from "../categoryListFormations/CategoryListFormations";
import FormationDetail from "../FormationDétails/FormationDetail";
import Loading from "components/loading/Loading";
import { BASE_URL_Image } from "services/server";
import SEO from "components/SEO/SEO";

const GroupImage = BASE_URL_Image + "storage/images/formations/kpis/group.svg";
const CartImage = BASE_URL_Image + "storage/images/formations/kpis/cart.svg";
const HeartImage = BASE_URL_Image + "storage/images/formations/kpis/heart.svg";
const RespectImage =
  BASE_URL_Image + "storage/images/formations/kpis/respect.svg";

const ImageFormation = styled("div")({
  width: "80%",
  height: "300px",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  borderRadius: "5px",
});

export default ({ formation, category, formations }) => {
  let navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const [kpis, setKpis] = useState([
    { icon: GroupImage, number: 0, name: "Stagiaires" },
    { icon: CartImage, number: 0, name: "Réussite" },
    { icon: HeartImage, number: 0, name: "Taux de Satisfaction" },
    { icon: RespectImage, number: 0, name: "Taux d'interruption" },
  ]);

  const [kpis2, setKpis2] = useState([
    { number: 80, name: "Taux de retour à l'emploi", extra: " %" },
    { number: 100, name: "Taux de réussite", extra: " % (2024)" },
    { number: 2, name: "Duré de formation", extra: " jours" },
    { number: 1, name: "Délais d'accès à la formation", extra: " Jours" },
  ]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (formation) {
      setLoading(true);
      setKpis([
        {
          icon: GroupImage,
          number: formation.nb_participants,
          name: "Stagiaires",
        },
        { icon: CartImage, number: formation.taux_reussite, name: "Réussite" },
        { icon: HeartImage, number: 100, name: "Taux de Satisfaction" },
        {
          icon: RespectImage,
          number: formation.taux_interruption,
          name: "Taux d'interruption",
        },
      ]);

      setKpis2([
        {
          number: formation.taux_insertion,
          name: "Taux de retour à l'emploi",
          extra: " %",
        },
        {
          number: formation.taux_reussite,
          name: "Taux de réussite",
          extra: " % (" + currentYear + ")",
        },
        {
          number: formation.duree_realisation,
          name: "Duré de formation",
          extra: " " + formation.heure_jour,
        },
        {
          number: formation.delais_acces,
          name: "Délais d'accès à la formation",
          extra: " Jours",
        },
      ]);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [formation, currentYear]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <AnimationRevealPage>
            {category && formation ? (
              <>
                <SEO metadata={formation.metadata} />
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <div className="w-100" style={{ maxWidth: "900px" }}>
                    <div className="row">
                      <div className="col-12 col-lg-6 p-5 d-flex align-items-center justify-content-center">
                        <div className="img-faqs w-100">
                          <ImageFormation
                            className="shadow"
                            style={
                              category && category.category_images.length > 0
                                ? {
                                    marginLeft: 0,
                                    marginTop: 0,

                                    backgroundImage:
                                      "url(" +
                                      BASE_URL_Image +
                                      category.category_images[0] +
                                      " )",
                                  }
                                : { marginLeft: 0, marginTop: 0 }
                            }
                          ></ImageFormation>
                          <ImageFormation
                            className="shadow"
                            style={
                              category && category.category_images.length > 1
                                ? {
                                    marginLeft: "20%",
                                    marginTop: "-70px",

                                    backgroundImage:
                                      "url(" +
                                      BASE_URL_Image +
                                      category.category_images[1] +
                                      " )",
                                  }
                                : {
                                    marginLeft: "20%",
                                    marginTop: "-70px",
                                  }
                            }
                          ></ImageFormation>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 p-5">
                        <FormationCard
                          formation={formation ? formation : null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 mb-3">
                  <KPIListComponent2 kpis={kpis2}></KPIListComponent2>
                </div>
                <div className="w-100">
                  <KPIListComponent kpis={kpis}></KPIListComponent>
                </div>

                <div className="w-100 d-flex align-items-center justify-content-center">
                  {/* {formation && formation.formation_link == "ho-bo" ? (

) : (
              <></>
            )} */}
                  {formation ? (
                    <FormationDetail
                      formation={
                        formation && formation.details
                          ? formation.details
                          : null
                      }
                    ></FormationDetail>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="w-100 mt-5 d-flex align-items-center justify-content-center">
                  {category && formations && formations.length > 0 ? (
                    <CategoryListFormations
                      formations={formations}
                      category={category}
                    ></CategoryListFormations>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </AnimationRevealPage>
        </>
      )}
    </>
  );
};
