import React from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  LinearProgress,
} from "@mui/material";
import { validatePhoneNumber } from "helpers/utils";
import { validateEmail } from "helpers/utils";
import { toast } from "react-toastify";
import { becomePartner } from "services/contactFormsService";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const PartnerForm = () => {
  const [Phone, setPhone] = useState("");
  const [Name, setName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");

  const [checkedItems, setCheckedItems] = useState({
    sst: false,
    ssiap1: false,
    ssiap2: false,
    ssiap3: false,
    autre: false,
  });

  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const { mutate: mutateSendEmail, isLoading: isLoadingSendEmail } =
    useMutation((formInfos) => becomePartner(formInfos), {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          notify_success("Votre message a été envoyé avec succès");
          setEmail("");
          setName("");
          setLastName("");
          setPhone("");
          setSubject("");
          setMessage("");
          setCheckedItems({
            sst: false,
            ssiap1: false,
            ssiap2: false,
            ssiap3: false,
            autre: false,
          });
        } else {
          notify("Une erreur est survenue!!");
        }
      },
      onError(error) {},
    });

  const send = () => {
    var form = {
      phone: Phone,
      firstname: Name,
      lastname: LastName,
      email: Email,
      message: Message,
      check_formation: JSON.stringify(checkedItems),
    };
    console.log(form);
    if (!Phone || Phone === "" || !validatePhoneNumber(Phone)) {
      notify("Entrez un numéro du téléphone valide s'il vous plaît !");
    } else if (!Name || Name === "") {
      notify("Entrez votre nom s'il vous plaît !");
    } else if (!LastName || LastName === "") {
      notify("Entrez votre prénom s'il vous plaît !");
    } else if (!Email || Email === "" || !validateEmail(Email)) {
      notify("Entrez un Email valide s'il vous plaît !");
    } else if (!Message || Message === "") {
      notify("Entrez votre message s'il vous plaît !");
    } else {
      mutateSendEmail(form);
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="Nom"
          required
          fullWidth
          value={Name}
          disabled={Phone === "" || !validatePhoneNumber(Phone)}
          onChange={(e) => setName(e.currentTarget.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Prénom"
          required
          fullWidth
          disabled={Phone === "" || !validatePhoneNumber(Phone)}
          value={LastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="E-mail"
          required
          fullWidth
          disabled={Phone === "" || !validatePhoneNumber(Phone)}
          type="email"
          value={Email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Telphone"
          required
          fullWidth
          value={Phone}
          onChange={(e) => setPhone(e.currentTarget.value)}
        />
      </Grid>
      {/* <Grid item xs={12}>
      <Typography variant="body1">Vos spécialités:</Typography>
      <FormGroup>
        <FormControlLabel control={<Checkbox />} label="Formation SST" />
        <FormControlLabel control={<Checkbox />} label="Formation SSIAP 1" />
        <FormControlLabel control={<Checkbox />} label="Formation SSIAP 2" />
        <FormControlLabel control={<Checkbox />} label="Formation SSIAP 3" />
        <FormControlLabel control={<Checkbox />} label="Autre" />
      </FormGroup>
    </Grid> */}
      <Grid item xs={12}>
        <Typography variant="body1">Vos spécialités:</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedItems.sst}
                onChange={handleChange}
                name="sst"
              />
            }
            label="Formation SST"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedItems.ssiap1}
                onChange={handleChange}
                name="ssiap1"
              />
            }
            label="Formation SSIAP 1"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedItems.ssiap2}
                onChange={handleChange}
                name="ssiap2"
              />
            }
            label="Formation SSIAP 2"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedItems.ssiap3}
                onChange={handleChange}
                name="ssiap3"
              />
            }
            label="Formation SSIAP 3"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedItems.autre}
                onChange={handleChange}
                name="autre"
              />
            }
            label="Autre"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Message"
          multiline
          rows={4}
          fullWidth
          value={Message}
          disabled={Phone === "" || !validatePhoneNumber(Phone)}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <button
          className="btn btn-outline-primary mx-0 mb-3 text-base w-100"
          style={{ display: "inline-block", fontWeight: "500" }}
          onClick={send}
        >
          Envoyer
        </button>
      </Grid>

      <Modal
        show={isLoadingSendEmail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </Grid>
  );
};

export default PartnerForm;
