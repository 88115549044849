import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import reducer from "./reducer";

const persistConfig = {
  key: "root",
  storage,
};
// ==============================|| REDUX - MAIN STORE ||============================== //
const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer);
const persister = persistStore(store);

export { store, persister };
