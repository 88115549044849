import React, { useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Grid,
  LinearProgress,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { validateEmail } from "helpers/utils";
import { validatePhoneNumber } from "helpers/utils";
import { useMutation } from "@tanstack/react-query";
import { sendContactForm } from "services/contactFormsService";
import { getFormationNames } from "services/formationsService";
import Loading from "components/loading/Loading";
import { useEffect } from "react";
import { reservation } from "services/contactFormsService";

// const formationsArray = [
//   { id: 1, nom_formation: "Formation 1" },
//   { id: 2, nom_formation: "Formation 2" },
//   { id: 3, nom_formation: "Formation 3" },
//   // Ajoutez d'autres formations au besoin
// ];

function RegistrationForm({ callback }) {
  const [formationsArray, setFormationsArray] = useState([]);
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("1996-11-16");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [formationChoice, setFormationChoice] = useState("");
  const [centreFormation, setCentreFormation] = useState("");

  const { mutate: mutateFormationsList, isLoading: isLoadingFormationsList } =
    useMutation(() => getFormationNames(), {
      async onSuccess(data) {
        console.log("getFormationNames");
        console.log(data);
        if (data && data.success) {
          setFormationsArray(data.data);
        } else {
          notify("Une erreur est survenue!!");
        }
      },
      onError(error) {},
    });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      mutateFormationsList();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  const { mutate: mutateSendEmail, isLoading: isLoadingSendEmail } =
    useMutation((formInfos) => reservation(formInfos), {
      async onSuccess(data) {
        console.log(data);
        if (data && data.success) {
          notify_success("Votre message a été envoyé avec succès");
          setEmail("");
          setFirstname("");
          setLastname("");
          setPhone("");
          setGender("");
          setAddress("");
          setPostCode("");
          setCity("");
          setFormationChoice("");
          setCentreFormation("");
          callback();
        } else {
          notify("Une erreur est survenue!!");
        }
      },
      onError(error) {},
    });

  const send = () => {
    var form = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      gender: gender,
      date: date,
      address: address,
      post_cod: postCode,
      city: city,
      phone: phone,
      formation_choice: formationChoice,
      centre_formation: centreFormation,
    };
    console.log(form);
    if (!phone || phone === "" || !validatePhoneNumber(phone)) {
      notify("Entrez un numéro du téléphone valide s'il vous plaît !");
    } else if (!firstname || firstname === "") {
      notify("Entrez votre nom s'il vous plaît !");
    } else if (!lastname || lastname === "") {
      notify("Entrez votre prénom s'il vous plaît !");
    } else if (!date || date === "") {
      notify("Entrez votre date s'il vous plaît !");
    } else if (!gender || gender === "") {
      notify("Entrez votre genre s'il vous plaît !");
    } else if (!address || address === "") {
      notify("Entrez votre adresse s'il vous plaît !");
    } else if (!postCode || postCode === "") {
      notify("Entrez votre code postal s'il vous plaît !");
    } else if (!city || city === "") {
      notify("Entrez votre ville s'il vous plaît !");
    } else if (!formationChoice || formationChoice === "") {
      notify("Entrez votre la formation souhaitée s'il vous plaît !");
    } else if (!centreFormation || centreFormation === "") {
      notify("Entrez votre le centre souhaité s'il vous plaît !");
    } else if (!email || email === "" || !validateEmail(email)) {
      notify("Entrez un Email valide s'il vous plaît !");
    } else {
      mutateSendEmail(form);
    }
  };

  const notify = (message) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      fontSize: "12px",
    });
  };

  const notify_success = (message) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
    });
  };

  return (
    <Grid container spacing={2}>
      <h3
        className="text-sm font-semibold mb-3 text-left"
        style={{ color: "#ff7300" }}
      >
        *Vous devez d'abord saisir le numéro de téléphone.
      </h3>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="gender-label">Genre</InputLabel>
          <Select
            labelId="gender-label"
            id="gender"
            value={gender}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            label="Genre"
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value="Homme">Homme</MenuItem>
            <MenuItem value="Femme">Femme</MenuItem>
            <MenuItem value="#NULL">Autre</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="firstname"
          label="Nom"
          name="firstname"
          value={firstname}
          disabled={phone === "" || !validatePhoneNumber(phone)}
          onChange={(e) => setFirstname(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="lastname"
          label="Prénom"
          name="lastname"
          value={lastname}
          disabled={phone === "" || !validatePhoneNumber(phone)}
          onChange={(e) => setLastname(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="email"
          label="E-mail"
          name="email"
          type="email"
          value={email}
          disabled={phone === "" || !validatePhoneNumber(phone)}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="date"
          label="Date de naissance"
          name="date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={date}
          disabled={phone === "" || !validatePhoneNumber(phone)}
          onChange={(e) => setDate(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          id="address"
          label="Adresse"
          name="address"
          value={address}
          disabled={phone === "" || !validatePhoneNumber(phone)}
          onChange={(e) => setAddress(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          id="postCode"
          label="Code Postal"
          name="postCode"
          value={postCode}
          disabled={phone === "" || !validatePhoneNumber(phone)}
          onChange={(e) => setPostCode(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          fullWidth
          id="city"
          label="Ville"
          name="city"
          value={city}
          disabled={phone === "" || !validatePhoneNumber(phone)}
          onChange={(e) => setCity(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="phone"
          label="Téléphone"
          name="phone"
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          inputProps={{ pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="formation-choice-label">Formation</InputLabel>
          <Select
            labelId="formation-choice-label"
            id="formation_choice"
            value={formationChoice}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            label="Formation"
            onChange={(e) => setFormationChoice(e.target.value)}
          >
            {formationsArray.map((formation) => (
              <MenuItem key={formation.id} value={formation.nom_formation}>
                {formation.nom_formation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="centre-formation-label">
            Centre de formation
          </InputLabel>
          <Select
            labelId="centre-formation-label"
            id="centre_formation"
            value={centreFormation}
            disabled={phone === "" || !validatePhoneNumber(phone)}
            label="Centre de formation"
            onChange={(e) => setCentreFormation(e.target.value)}
          >
            <MenuItem value="Paris">Centre de formation à Paris</MenuItem>
            <MenuItem value="Nanterre">Centre de formation à Nanterre</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <button
          className="btn btn-outline-primary mx-0 mb-3 text-base w-100"
          style={{ display: "inline-block", fontWeight: "500" }}
          onClick={send}
        >
          Envoyer ma demande
        </button>
      </Grid>

      {isLoadingFormationsList ? (
        <>
          <Loading></Loading>
        </>
      ) : (
        <></>
      )}

      <Modal
        show={isLoadingSendEmail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="myModalLoading"
      >
        <Modal.Body style={{ overflow: "hidden" }}>
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        </Modal.Body>
      </Modal>
    </Grid>
  );
}

export default RegistrationForm;
